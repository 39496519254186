import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-subportal',
  templateUrl: './subportal.component.html',
  styleUrls: ['./subportal.component.css']
})
export class SubportalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
