import { Component, OnInit, Inject, Injectable, ViewChild, ElementRef, TemplateRef} from '@angular/core';
import { MainService } from '../../main.service';
import { SESSION_STORAGE, StorageService } from 'angular-webstorage-service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { ToastModule } from 'primeng/toast';
import { Message } from 'primeng/api';
import { MessageService } from 'primeng/api';
import * as moment from 'moment';

@Component({
  selector: 'tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.css']
})
export class TicketsComponent implements OnInit {

  @ViewChild('tickets') private modalContent: TemplateRef<TicketsComponent>
  private modalRef: NgbModalRef

  constructor(private modalService: NgbModal, @Inject(SESSION_STORAGE) public storage: StorageService, public mainService: MainService, public messageService: MessageService) { }

  ngOnInit(): void {
  }

  open(){
    this.modalRef = this.modalService.open(this.modalContent)
    this.modalRef.result.then()
  }

  close(){
    this.modalRef.close()
  }

  dismiss(){
    this.modalRef.dismiss()
  }

}
