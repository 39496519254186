import { Inject, Injectable } from '@angular/core';
import { SESSION_STORAGE, StorageService } from 'angular-webstorage-service';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';;
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {MainService} from './main.service';
import { mergeMap } from 'rxjs/operators';

/**
 * This interceptor automatically adds the token header needed by our backend API if such token is present
 * in the current state of the application.
 */
@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {

  constructor(@Inject(SESSION_STORAGE) private storage: StorageService, private mainService: MainService) {}

  // intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  //   console.log('INTERCEPTOR');
  //   const token = this.mainService.getAuthToken();
  //   let newHeaders = req.headers;
  //   if (token) {
  //     newHeaders = newHeaders.append('authtoken', token);
  //   }
  //   const authReq = req.clone({headers: newHeaders});
  //   return next.handle(authReq).pipe(
  //     map(resp => {
  //       if (resp instanceof HttpResponse) {
  //         console.log(resp);
  //         return  resp.clone({ body: [{title: 'Replaced data in interceptor'}] });
  //       }
  //     })
  //   );
  // }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token: string = this.storage.get('token');

    if (token) {
        request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
    }

    if (!request.headers.has('Content-Type')) {
        request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
    }

    request = request.clone({ headers: request.headers.set('Accept', 'application/json') });

    return next.handle(request).pipe(
        map((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                // console.log('event--->>>', event);
            }
            return event;
        }));
}

}