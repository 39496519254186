import { Component, OnInit, Inject, Injectable, ViewChild } from '@angular/core';
import { MainService } from '../../main.service';
import { SESSION_STORAGE, StorageService } from 'angular-webstorage-service';
import { ActivatedRoute, Router } from '@angular/router';

import {ToastModule} from 'primeng/toast';
import { Message } from 'primeng/api';
import { MessageService } from 'primeng/api';

import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html',
  providers: [MessageService]
})
export class LoginComponent implements OnInit {

  public showAlert: boolean = true;
  public msgs: Message[] = [];
  public msgsGrowl: Message[] = [];


  email: string;
  password: string;
  loading: any;
  token: any;
  user: any;
  public countries = [];
  public customers: any;
  tid: any;
  cid:any;

  @ViewChild('smallModal') public smallModal: ModalDirective;


  constructor(@Inject(SESSION_STORAGE) public storage: StorageService, public mainService: MainService, private route: ActivatedRoute, private router: Router, public messageService: MessageService) { }

  ngOnInit(): void{

    this.token = this.storage.get('email');

    if(this.token != null){
      this.email = this.token;
    }else{
      this.email = "";
    }

    this.checkToken();


  }

  async checkToken(){
    var user = this.storage.get('currentUser');

    var token = this.storage.get('token');

      if (token != null || token != undefined) {
        let details = {
          code: token,
        };
        let url = 'account/fastpass';
        this.mainService.createData(details, url).subscribe((value) => {
          this.storage.set('currentUser', user);
          this.storage.set('userid', user._id);
          this.storage.set('companyid', user.company._id);
          this.storage.set('token', value.token);
          this.router.navigate(['/home']);
        }, (err) => {
        });
      } else {
      }
  }

  login() {

    let credentials = {
      email: this.email,
      password: this.password
    };
    let cred = this.email;
    this.storage.set('email', cred);
    let link = "auth/login";
    console.log(credentials);
    this.mainService.createData(credentials, link).subscribe((result) => {
      console.log(cred);
      console.log(result);
      console.log(result.user);
      console.log(result.token);
       this.storage.set('currentUser', result.user);
       this.storage.set('userid', result.user._id);
      this.storage.set('token', result.token);
      this.router.navigate(['/home']);
    }, (err) => {
    this.messageService.add({severity:'error', summary: 'Invalid Credentials', detail:'Please Try Again.'});
    this.password = "";
    if(this.token != null){
     this.email = this.token;
    }else{
     this.email = "";
    }
      console.log(err);
    });
    
    
    }

    register(){
      this.router.navigate(['/register']);
    }

    sendReset() {

      let credentials = {
        email: this.email,
      };
      let link = "auth/requestpass/";
      console.log(credentials);
      this.mainService.createData(credentials, link).subscribe((result) => {
        this.messageService.add({severity:'success', summary: 'Request Successful', detail:'Request Complete, Check Your Email.'});
        this.smallModal.hide()
      }, (err) => {
      this.messageService.add({severity:'error', summary: 'Request Failed', detail:'Please Try Again.'});
        console.log(err);
      });
      
      
      }

}
