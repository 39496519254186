import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { AppComponent } from './app.component';
import { FilterPipe } from './filter.pipe';

import { OverlayPanelModule } from 'primeng/overlaypanel';
import { SidebarModule } from 'primeng/sidebar';
import { ToastModule } from 'primeng/toast';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { DialogModule } from 'primeng/dialog';
import {InputMaskModule} from 'primeng/inputmask';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { DropdownModule } from 'primeng/dropdown';
import {CalendarModule} from 'primeng/calendar';

import { GoogleMapsModule } from '@angular/google-maps';

import { StorageServiceModule } from 'angular-webstorage-service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptorService } from './interceptor';
import { MainService } from './main.service';
import { PagesModule } from './pages/pages.module';

// Import containers
import { DefaultLayoutComponent } from './containers';
import { TextMaskModule } from 'angular2-text-mask';
import { NgxMaskModule, IConfig } from 'ngx-mask';

import { ModalModule } from 'ngx-bootstrap/modal';
 
export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';
import { LandingComponent } from './pages/landing/landing.component';

const APP_CONTAINERS = [
  DefaultLayoutComponent
];

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartsModule } from 'ng2-charts';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PdfViewerModule } from 'ng2-pdf-viewer';


@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppAsideModule,
    CalendarModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ChartsModule,
    SidebarModule,
    OverlayPanelModule,
    MessagesModule,
    MessageModule,
    DialogModule,
    ToastModule,
    InputMaskModule,
    DropdownModule,
    StorageServiceModule,
    FormsModule,
    HttpClientModule,
    PagesModule,
    TextMaskModule,
    GoogleMapsModule,
    ModalModule.forRoot(),
    NgxMaskModule.forRoot(options),
    NgxLoadingModule.forRoot({
			animationType: ngxLoadingAnimationTypes.circle,
backdropBackgroundColour: 'rgba(0,0,0,0.1)',
backdropBorderRadius: '4px',
primaryColour: '#000026',
secondaryColour: '#e0b500',
tertiaryColour: '#e0b500'
		}),
    NgbModule,
    PdfViewerModule,
  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    P404Component,
    P500Component,
    LoginComponent,
    RegisterComponent,
    LandingComponent,
    FilterPipe
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true},
    MainService,
    FilterPipe
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
