import { Component, OnInit, Inject, Injectable, ViewChild, ElementRef, TemplateRef} from '@angular/core';
import { MainService } from '../../main.service';
import { SESSION_STORAGE, StorageService } from 'angular-webstorage-service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Routes, RouterModule, ActivatedRoute, Router, NavigationEnd } from '@angular/router';

import { ToastModule } from 'primeng/toast';
import { Message } from 'primeng/api';
import { MessageService } from 'primeng/api';
import * as moment from 'moment';

@Component({
  selector: 'events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css']
})
export class EventsComponent implements OnInit {

  @ViewChild('events') private modalContent: TemplateRef<EventsComponent>
  private modalRef: NgbModalRef

  public upcoming: any;
  public previous: any;

  public showButton: boolean = false;

  public showEventCreate: boolean = false;
  public showAllEvents: boolean = false;

  public noResults: boolean = true;
  public noResults1: boolean = true;

    // create and edit variables
    selectedDate:any;
    eTime:any;
    endTime:any;
    eventName:any;
    type:any;
    serviceNotes:any;
    creator:any;
    fullAddress:any;
    owner:any;
    jobColor:any;
    customerid:any;
    projectid:any;
    estimateID:any;
    recipient:any;
    assignedUsers:any;
    viewtype:any;
  startTOD:any;
  endTOD: any;
    results:any;

    colorName: any;

    employees: any;

    types: any;
    jobColors: any;
    availabilities: any;

    equipment: any;


    scheduleAvailability:any;

    posturl:any;

    //for maps

      //Maps 
  zoom = 10;
  center: google.maps.LatLngLiteral
  options: google.maps.MapOptions = {
    mapTypeId: 'roadmap',
    zoomControl: true,
    scrollwheel: true,
    disableDoubleClickZoom: true,
    maxZoom: 15,
    minZoom: 8,
  }
  markers = [];

  startDate: any;
  endDate: any;

  currentFilter: any;
  currentFilterID:any;
  typeFilter:any;
  typeVariable: any;

  constructor(private modalService: NgbModal, @Inject(SESSION_STORAGE) public storage: StorageService, public mainService: MainService, public messageService: MessageService, private router: Router) { }

  ngOnInit(): void {
    navigator.geolocation.getCurrentPosition((position) => {
      this.center = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      }
    })
  }

  open(options){
    this.modalRef = this.modalService.open(this.modalContent,
      {
      size: 'xl'
      })
    this.modalRef.result.then()
    if(options.type == 'Get'){
      this.showAll(options.url);
    }
    if(options.type == 'Create'){
      this.getEmployees();
      this.showCreate();
      this.customerid = options.CustID;
      this.projectid = options.ProjectID;
      this.estimateID = options.EstimateID;
      this.equipment = options.equipment;
      this.posturl = options.url;
      this.viewtype = options.viewtype;
      this.fullAddress = options.address;
      this.ngOnInit();
    }
    if(options.type == 'Edit'){
      this.getEmployees();
      this.convertColor(options.event.jobColor);
      this.showEdit(options.event);
      this.ngOnInit();
      this.posturl = options.url;
      this.viewtype = options.viewtype;
      this.customerid = options.CustID;
      this.projectid = options.ProjectID;
      this.estimateID = options.EstimateID;
      this.equipment = options.equipment;

    }
    this.jobColors = [
      { name: 'Red', value: '#ff0000' },
      { name: 'Blue', value: '#00007f' },
      { name: 'Green', value: '#198c19' },
      { name: 'Yellow', value: '#ffff00' },
      { name: 'Purple', value: '#800080' },
      { name: 'Light Blue', value: '#4befff' },
      { name: 'Pink', value: '#FFC0CB' },
      { name: 'Olive', value: '#7fbf7f' },
      { name: 'Orange', value: '#FFA500' },
    ];
    this.types = [
      { name: 'Service', value: 'Service' },
      { name: 'Estimate', value: 'Estimate' }
    ];
    this.availabilities = [
      { name: 'Yes', value: 'Open' },
      { name: 'No', value: 'Fixed' },
    ];

  }

  close(){
    this.modalRef.close()
  }

  dismiss(){
    this.modalRef.dismiss()
  }

  getEmployees(){
    let user = this.storage.get('currentUser');
  let id = user.company._id;
  let url = 'secureauth/getemployees/' + id;
  this.mainService.getData(url).subscribe(res => {
    this.employees = res;
    for (var i = 0; i < res.length; i++) {
      var information = {
        details: "Employee: " + res[i].fullName + ": Updated at " + res[i].locTime,
        longitude: res[i].longitude,
        latitude: res[i].latitude
      }

      console.log("Position =" + res[i].latitude);

      this.markers.push({
        position: {
          lat: res[i].latitude,
          lng: res[i].longitude,
        },
        label: {
          color: 'black',
          fontSize: "10px",
          fontWeight: "bold",
          text: res[i].fullName + ": Updated at " + res[i].locTime
        },
        title: res[i].fullName + ": Updated at " + res[i].locTime,
        info: information,
        options: {
          animation: google.maps.Animation.DROP,
        },
      })
    }
  }, err => {
    console.log(err);
  });
}

  showCreate() {
    this.recipient = [];
    this.assignedUsers = [];
    let user = this.storage.get('currentUser');
    if (user.pageAccess.includes("CREATEEVENT") == true) {
      this.showEventCreate = true;
      this.showAllEvents = false;
      this.eTime = "08:00 AM";
      this.endTime = "04:00 PM";
      this.startTOD = "2021-01-27T13:00:00Z";
      this.endTOD = "2021-01-27T21:00:00Z";
      this.owner = user.company._id;
      this.creator = user._id;
      this.recipient.push(user._id);
      this.assignedUsers.push(user.fullName);
      this.type = { name: "Service", value: "Service"};
      this.jobColor = { name: "Red", value: "#ff0000"};
      this.scheduleAvailability = { name: "Yes", value: "Open" };
      this.selectedDate = moment().format("MM/DD/YYYY");
      this.selectDate();
    } else {
      this.dismiss();
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Not Authorized' });
    }
  }

    // Show Edit Card
    showEdit(item) {
      if(item.scheduleAvailability == 'Open'){
        var schName = "Yes";
      }else{
        var schName = "No";
      }
      this.recipient = [];
      this.assignedUsers = [];
       let user = this.storage.get('currentUser');
       if (user.pageAccess.includes("EDITEVENT") == true) {
        this.owner = user.company._id;
        this.showEventCreate = true;
        this.showAllEvents = false;
         this.showButton = true;
         this.selectedDate = item.selectedDate;
         this.eTime = item.eTime;
         this.endTime = item.endTime;
         this.startTOD = item.startTOD;
         this.endTOD = item.endTOD;
         this.eventName = item.eventName;
         this.type = item.type;
         this.serviceNotes = item.serviceNotes;
         this.fullAddress = item.fullAddress;
         this.jobColor = item.jobColor;
         this.recipient = item.recipient;
         this.assignedUsers = item.assignedUsers;
         this.scheduleAvailability = item.scheduleAvailability;
         this.type = { name: item.type, value: item.type };
         this.jobColor = { name: this.colorName, value: item.jobColor};
         this.scheduleAvailability = { name: schName, value: item.scheduleAvailability };

        //  for (var i = 0; i < item.recipient.length; i++) {
        //    this.recipient.push(item.recipient[i]._id)
        //  }
        //  for (var i = 0; i < item.assignedUsers.length; i++) {
        //   this.assignedUsers.push(item.assignedUsers[i]._id)
        // }

        console.log()
       } else {
        this.dismiss();
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Not Authorized' });
       }
       this.selectDate();
     }

     convertColor(color){
       if(color == "#ff0000"){
        this.colorName = "Red"
       }
       if(color == "#00007f"){
        this.colorName = "Blue"
       }
       if(color == "#198c19"){
        this.colorName = "Green"
       }
       if(color == "#ffff00"){
        this.colorName = "Yellow"
       }
       if(color == "#800080"){
        this.colorName = "Purple"
       }
       if(color == "#4befff"){
        this.colorName = "Light Blue"
       }
       if(color == "#FFC0CB"){
        this.colorName = "Pink"
       }
       if(color == "#7fbf7f"){
        this.colorName = "Olive"
       }
       if(color == "#FFA500"){
        this.colorName = "Orange"
       }
}

  showAll(url){
    this.previous = [];
    this.upcoming = [];
    let user = this.storage.get('currentUser');
    if(user.pageAccess.includes("CREATEEVENT") == true){
      this.showAllEvents = true;
      this.showEventCreate = false;
      this.mainService.getData(url).subscribe(res => {
        let today = moment().format("YYYYMMDD");
        console.log(today);
        for (var i = 0; i < res.length; i++) {
          console.log(res[i].eventDate);
          if (res[i].eventDate >= today){
            this.upcoming.push(res[i]);
            this.noResults = false;
          }else{
            this.previous.push(res[i]);
            this.noResults1 = false;
          }
      }
      if(i == res.length){
        this.upcoming.sort(this.sortAll);
        this.previous.sort(this.sortAll);
      }

       })
      }else{
        this.dismiss();
      }
  }

  sortAll(a,b){
    let comparison = 0;
    if (a.eventDate > b.eventDate) {
      comparison = 1;
    } else if (a.eventDate < b.eventDate) {
      comparison = -1;
    }

  }

  openEvent(result){
    this.dismiss();
    this.router.navigate(['/event/' + result._id]);
  }


   prepSend() {

    console.log("Create clicked");

    this.selectedDate = moment(this.selectedDate).format("MM/DD/YYYY");

    console.log(this.eTime + "  " + this.endTime);
    console.log(this.startTOD + "  " + this.endTOD);

    let start = moment(this.eTime).format('h:mm A');
    let end = moment(this.endTime).format('h:mm A');

    console.log(start + end);

    if(start == 'Invalid date'){
      this.eTime = this.eTime
      this.startTOD = this.startTOD;
    }else{
      this.eTime = start;
      // this.startTOD = this.eTime;
      this.startTOD = moment(this.selectedDate + "," + this.eTime).format();
    }
    if(end == 'Invalid date'){
      this.endTime = this.endTime;
      this.endTOD = this.endTOD
    }else{
      this.endTime = end;
      // this.endTOD = this.endTime;
      this.endTOD = moment(this.selectedDate + "," + this.endTime).format();
    }
    let details = {
      selectedDate: this.selectedDate,
      eTime: this.eTime,
      endTime: this.endTime,
      startTOD: this.startTOD,
      endTOD:this.endTOD,
      eventName: this.eventName,
      type: this.type.value,
      serviceNotes: this.serviceNotes,
      creator: this.creator,
      fullAddress: this.fullAddress,
      owner: this.owner,
      jobColor: this.jobColor.value,
      customerid: this.customerid,
      projectid: this.projectid,
      estimateID: this.estimateID,
      recipient: this.recipient,
      assignedUsers: this.assignedUsers,
      scheduleAvailability: this.scheduleAvailability.value,
      equipment: this.equipment
    };

    console.log(details);
    if (this.viewtype == 'Create') {
      this.sendCreate(details);
      console.log('Create ' + details);
    }

    if (this.viewtype == 'Edit') {
      this.sendEdit(details);
      console.log('Edit ' + details);
    }
  }

  sendCreate(data) {
    this.mainService.createData(data, this.posturl).subscribe((result) => {
      console.log(result);
      this.dismiss();
      this.router.navigate(['/event/' + result._id]);
    }, (err) => {
      this.showButton = true;
    });
  }

  sendEdit(data) {
    this.mainService.editData(data, this.posturl).subscribe((result) => {
      console.log(result);
      this.dismiss();
      this.router.navigate(['/event/' + result._id]);
    }, (err) => {
      this.showButton = true;
    });
  }

  async assignUser(result) {
    var n = this.recipient.includes(result._id) && this.assignedUsers.includes(result.fullName);
    if((n == true) && this.recipient.length <= 1){
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'At Least One Account Manager Required!' });
      console.log("Not ENough Managers");
    }
    if ((n == true) && (this.recipient.length > 1)) {
      var index = this.recipient.indexOf(result._id);
      if (index > -1) {
        this.recipient.splice(index, 1)
      }
      var index1 = this.assignedUsers.indexOf(result.fullName);
      if (index1 > -1) {
        this.assignedUsers.splice(index, 1)
      }
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Employee Removed' });

    } 
    if (n == false){
      this.recipient.push(result._id);
      this.assignedUsers.push(result.fullName);
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Employee Added' });

    }
  }

  async selectDate() {
    console.log(this.selectedDate);
    this.showButton = true;
    let user = this.storage.get('currentUser');

    let input = moment(this.selectedDate).format("MM/DD/YYYY");
    console.log(input)
    let url = 'schedule/calendar/' + user.company._id;
    let details = {
      inputDate: input,
    };
    this.mainService.createData(details, url).subscribe((result) => {
      this.markers = [];
      // this.getEmployees();
      // this.setMarkers(result);
      // this.results = result;
      let returned = this.userFilter(result);
      console.log(returned);
      this.results = returned;
      this.markers = [];
      this.getEmployees();
      this.setMarkers(returned);
      if (returned.length == 0) {
        this.noResults = true;
      } else {
        this.noResults = false;
      }
    }, (err) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please Try Again' });
    });
  }

  viewEventMarker(){
    let url = 'schedule/coords';
    const image = "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png";
    let details = {
      address: this.fullAddress,
    };
    this.mainService.createData(details, url).subscribe((result) => {
      console.log('Return Address' + result);
      var information = {
        details: "New Event at " + " - " + this.fullAddress,
        longitude: result.longitude,
        latitude: result.latitude
        // longitude: result.position.lng,
        // latitude: result.position.lat
      }
      this.markers.push({
        position: {
          lat: result.latitude,
          lng: result.longitude
        },
        label: {
          color: 'black',
          text: "New Event at " + " - " + this.fullAddress
        },
        title: "New Event at " + " - " + this.fullAddress,
        info: information,
        options: {
          animation: google.maps.Animation.BOUNCE,
          icon: image,
        },
      })
      console.log(result)
    });
}

openInfo(content) {
  this.messageService.add({ severity: 'success', summary: 'Info Alert', detail: content.details });
  this.zoom = 14;
  this.center = {
    lat: content.latitude,
  lng: content.longitude,
  }        
}

closeInfo(){
  this.zoom = 10
}

  setMarkers(passed) {
    for (var i = 0; i < passed.length; i++) {
      console.log(passed[i]);

      let name = passed[i].customerid.fullName;
      let address = passed[i].fullAddress ;
      let type = passed[i].type;

      let lti = (passed[i].latitude * 1);
      let ltg = (passed[i].longitude * 1);

      let details = {
        address: address,
      };
      let url = 'schedule/coords';
      const image =
      "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png";
        var information = {
          details: name + " - " + address +  " - " + type,
          longitude: ltg,
          latitude: lti
        }
        this.markers.push({
          position: {
            lat: lti,
            lng: ltg
          },
          label: {
            color: 'black',
            text: name + " - " + address +  " - " + type
          },
          title: name + " - " + address +  " - " + type,
          info: information,
          options: {
            animation: google.maps.Animation.DROP,
            icon: "https://maps.google.com/mapfiles/ms/icons/blue-dot.png",
          },
        })
    }

  }

  getRange(){
    let user = this.storage.get('currentUser');
      let url = 'schedule/range/' + user.company._id;
      let input = moment(this.startDate).format("MM/DD/YYYY");
      let input1 = moment(this.endDate).format("MM/DD/YYYY");
      let details = {
        startDate: input,
        endDate: input1
      };
      console.log(details);
      this.mainService.createData(details, url).subscribe((result) => {

        this.successMessage();
        let returned = this.userFilter(result);
        console.log(returned);
        this.results = returned;
        this.markers = [];
        this.getEmployees();
        this.setMarkers(returned);
        if(returned.length == 0){
          this.noResults = true;
        }else{
          this.noResults = false;
        }
      }, (err) => {
        this.errorMessage();
      });

  }

  successMessage() {
    this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Request Complete' });
  }

  errorMessage() {
    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please Try Again' });
  }


  addUserFilter(user){
    this.currentFilter = user.fullName;
    this.currentFilterID = user._id;
    // this.filterModal.hide();
    this.successMessage();

  }

  removeUserFilter(){
    this.currentFilter = null;
    this.currentFilterID = null;
    // this.filterModal.hide();
    this.successMessage();
  }

  addTypeFilter(value){
    this.typeFilter = value;
    this.successMessage();
  }

  removeTypeFilter(){
    this.typeFilter = null;
    this.successMessage();
  }
  removeAllFilter(){
    this.typeFilter = null;
    this.currentFilter = null;
    this.currentFilterID = null;
    this.successMessage();
  }

  userFilter(results){
    let filtered = [];
    console.log("1 " + results);

    if(this.currentFilterID != null){
      for (var i = 0; i < results.length; i++) {
        let check = results[i].recipient.includes(this.currentFilterID)
        if(check == true){
          filtered.push(results[i]);
        }
        let tally = (i+1);
        console.log(tally);
  
        if(tally == results.length){
          console.log("2 " + filtered);
          let final = this.filterType(filtered);
          console.log("5" + final);
          return final
        }
  
      }
    }else{
      console.log("No Filter");
      let final = this.filterType(results);
      return final;
    }
  }

  filterType(results){
    console.log("3 " + results); 
    let filtered = [];
    if(this.typeFilter != null){
    for (var i = 0; i < results.length; i++) {
      let check = results[i].type == this.typeFilter;
      console.log("6" + check + results[i].type + this.typeFilter);
      if(check == true){
        filtered.push(results[i]);
      }
      let tally = (i+1);
      console.log(tally);

      if(tally == results.length){
        console.log("4 " + filtered);
        return filtered

      }

    }
  }else{
    console.log("No Filter");
    return results;
  }

  }

}
