import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';


import { HomeComponent } from './pages/home/home.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { LandingComponent } from './pages/landing/landing.component';
import { AdminComponent } from './pages/admin/admin.component';
import { CalendarComponent } from './pages/calendar/calendar.component';
import { AccountingComponent } from './pages/accounting/accounting.component';

import { EventComponent } from './pages/event/event.component';

import { PasswordComponent } from './pages/password/password.component';

export const routes: Routes = [
  {
    path: '',
    // redirectTo: 'landing',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register Page'
    }
  },
  {
    path: 'landing',
    component: LandingComponent,
    data: {
      title: 'Landing Page'
    }
  },
  {
    path: 'reset/:email/:code',
    component: PasswordComponent,
    data: {
      title: 'Password Reset'
    }
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
      // { path: '', component: , }, Route Template
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      { path: 'home', component: HomeComponent, runGuardsAndResolvers: "always" },
      { path: 'admin', component: AdminComponent, runGuardsAndResolvers: "always"},
      { path: 'profile/:id', component: ProfileComponent, runGuardsAndResolvers: "always"},
      { path: 'calendar', component: CalendarComponent, runGuardsAndResolvers: "always"},
      { path: 'accounting', component: AccountingComponent, runGuardsAndResolvers: "always"},
      { path: 'event/:id', component: EventComponent, runGuardsAndResolvers: "always"},
    ]
  },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes, {onSameUrlNavigation: "reload"}) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
