import { Component, OnInit, Inject, Injectable, ViewChild, ElementRef, TemplateRef} from '@angular/core';
import { MainService } from '../../main.service';
import { SESSION_STORAGE, StorageService } from 'angular-webstorage-service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { ToastModule } from 'primeng/toast';
import { Message } from 'primeng/api';
import { MessageService } from 'primeng/api';
import * as moment from 'moment';

import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.css'],
  providers: [MessageService]
})
export class ContactsComponent implements OnInit {
  @ViewChild('contacts') private modalContent: TemplateRef<ContactsComponent>
  private modalRef: NgbModalRef

  @ViewChild('contactModal') public contactModal: ModalDirective;

  public id: any;
  public user: any;

  public result: any;
  public results: any;

  public showButton: boolean = false;
  public noResults: boolean = false;

  public showContactCreate: boolean = false;
  public showAllContacts: boolean = false;
  public showOneContact: boolean = false;
  public showContactEdit: boolean = false;
  public showContactDel: boolean = false;

  public showInitial: boolean = true;




   //Customer and Contact Variables
   firstName: string;
   lastName: string;
   street: string;
   city: string;
   state: string;
   secondary: string;
   fax: string;
   zipcode: string;
   fullAddress: string;
   phone: string;
   public contType: any;
   public contTypes: any;
   email: string;
   public statuses: any;
   public types: any;
   public sources: any;
   contactphone: any;

  constructor(private modalService: NgbModal, @Inject(SESSION_STORAGE) public storage: StorageService, public mainService: MainService, public messageService: MessageService) { }

  ngOnInit(): void {
    this.initializePage();
  }

  initializePage(){
    this.user = this.storage.get('currentUser');
    this.id = this.storage.get('customer');

    this.contTypes = [
      { name: 'Customer', value: 'Customer' },
      { name: 'Sub Contractor', value: 'Sub Contractor' },
      { name: 'Vendor', value: 'Vendor' }
    ];
    this.showContactCreate = false;
    this.showAllContacts = false;
    this.showOneContact = false;
    this.showContactEdit = false;
    this.showContactDel = false;
    this.showInitial = true;
    this.showButton = false;
    this.results = [];
    this.result= "";
  }

  retrieveMain() {
    this.showContactMain();
    this.showContactCreate = false;
    this.showAllContacts = true;
    this.showOneContact = false;
    this.showContactEdit = false;
    this.showContactDel = false;
    this.showInitial = false;
    this.showButton = false;
  }

  retrieveCreate() {
    if (this.user.pageAccess.includes("CREATECONTACT")) {
      this.showButton = true;
      this.showContactCreate = true;
      this.showAllContacts = false;
      this.showOneContact = false;
      this.showContactEdit = false;
      this.showContactDel = false;
      this.showInitial = false;
      this.firstName = "";
      this.lastName = "";
      this.street = "";
      this.city = "";
      this.state = "";
      this.zipcode = "";
      this.phone = "";
      this.email = "";
      this.contType = { name: "", value: "" };
    } else {
      this.authorizedError();
    }
  }

  retrieveEdit() {
    if (this.user.pageAccess.includes("EDITCONTACT")) {
      this.showButton = true;
      this.showContactEdit = true;
      this.showContactCreate = false;
      this.showAllContacts = false;
      this.showOneContact = false;
      this.showContactDel = false;
    } else {
      this.authorizedError();
    }
  }

  retrieveEdit1(item) {
    if (this.user.pageAccess.includes("EDITCONTACT")) {
      this.result = item;
      this.showContactEdit = true;
      this.showContactCreate = false;
      this.showAllContacts = false;
      this.showOneContact = false;
      this.showContactEdit = true;
      this.showContactDel = false;
      this.showButton = false;
      this.contType = { name: item.contType, value: item.contType };
      if (item.phone != null) {
        this.contactphone = item.phone.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")
      }
    } else {
      this.authorizedError();
    }
  }

    //Begin Tab Section Functions

    successMessage() {
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Request Complete' });
    }
  
    errorMessage() {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please Try Again' });
    }

    authorizedError() {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Not Authorized' });
    }

  open(){
    this.ngOnInit();
    this.modalRef = this.modalService.open(this.modalContent,
      {
      size: 'lg'
      });
    this.modalRef.result.then();
  }

  close(){
    this.modalRef.close();
    this.storage.set('customer', "");
    this.storage.set('geturl', "");
    this.showContactCreate = false;
    this.showAllContacts = false;
    this.showOneContact = false;
    this.showContactEdit = false;
    this.showContactDel = false;
    this.showInitial = true;
    this.showButton = false;
    this.results = [];
    this.result= "";
  }

  dismiss(){
    this.modalRef.dismiss();
    this.storage.set('customer', "");
    this.storage.set('geturl', "");
    this.showContactCreate = false;
    this.showAllContacts = false;
    this.showOneContact = false;
    this.showContactEdit = false;
    this.showContactDel = false;
    this.showInitial = true;
    this.showButton = false;
    this.results = [];
    this.result= "";
  }

    // Begin Contacts Section


  // Create New Item
  async createNewContact() {
    let id = this.storage.get('customer');
    if (this.phone != null) {
      var p1 = this.phone.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")
    } else {
      p1 = ""
    }
    this.showButton = false;
    let details = {
      firstName: this.firstName,
      lastName: this.lastName,
      street: this.street,
      city: this.city,
      state: this.state,
      zipcode: this.zipcode,
      fullAddress: this.street + " " + this.city + ", " + this.state + " " + this.zipcode,
      phone: p1,
      email: this.email,
      contType: this.contType.value,
      customer: id,
    };
    let url = 'contact/create/';
    this.mainService.createData(details, url).subscribe((result) => {
      this.successMessage();
      this.showContactCreate = false;
      this.showSelectContact(result._id);
      this.firstName = "";
      this.lastName = "";
      this.street = "";
      this.city = "";
      this.state = "";
      this.zipcode = "";
      this.phone = "";
      this.email = "";
    }, (err) => {
      this.showButton = true;
      this.errorMessage();
    });
  }

  editContact(result) {
    this.showButton = false;
    if (result.phone != null) {
      result.phone = result.phone.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")
    } else {
      result.phone = ""
    }
    result.fullAddress = result.street + " " + result.city + ", " + result.state + " " + result.zipcode;
    this.result.contType = this.contType.value;
    let url = 'contact/edit/' + result._id;
    this.mainService.editData(result, url).subscribe((result) => {
      this.showSelectContact(result._id);
      this.successMessage();
      console.log(result);
    }, (err) => {
      this.showButton = true;
      console.log("An Error Occurred.");
      this.errorMessage();
    });
  }

  showSelectContact(id) {
    //Check Permissions
    if (this.user.pageAccess.includes("VIEWCONTACT")) {
      this.showContactCreate = false;
      this.showAllContacts = false;
      this.showContactEdit = false;
      this.showContactDel = false;
      this.showOneContact = true;
      let url = 'contact/get/getone&' + id;
      this.mainService.getData(url).subscribe(res => {
        this.result = res;
        this.contType = { name: res.contType, value: res.contType };
        if (res.phone != null) {
          this.contactphone = res.phone.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")
        }
      })
    } else {
      this.authorizedError();
      this.showOneContact = false;
    }
  }

  showContactMain() {
    //Check Permissions
    if (this.user.pageAccess.includes("VIEWCONTACT")) {
      let id = this.storage.get('customer');
      let url = 'contact/get/getall&' + id;
      this.mainService.getData(url).subscribe(res => {
        this.results = res;
        if(res.length == 0)
        {
          this.noResults = true;
        }else{
          this.noResults = false;
        }
      })
    } else {
      this.authorizedError();
      this.modalRef.close();
    }
  }

  showContactDelete(item) {
    //Check Permissions
    if (this.user.pageAccess.includes("DELETECONTACT")) {
      this.result = item;
      this.showContactDel = true;
    } else {
      this.authorizedError();
    }
  }

  hideContactDelete(){
    this.showContactDel = false;
  }


  // Delete Item and Return to View All
  deleteContact(id) {
    let url = 'contact/delete/' + id;
    this.mainService.deleteData(url).subscribe(res => {
      this.successMessage();
      this.retrieveMain();
      this.showContactDel = false;
    }, (err) => {
      console.log("An Error Occurred.");
      this.errorMessage();
    });
  }


}
