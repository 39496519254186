import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-estimates',
  templateUrl: './estimates.component.html',
  styleUrls: ['./estimates.component.css']
})
export class EstimatesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
