import { Component, OnInit, Inject, Injectable } from '@angular/core';
import { MainService } from '../../main.service';
import { SESSION_STORAGE, StorageService } from 'angular-webstorage-service';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterPipe } from '../../filter.pipe';
import { OverlayPanel } from 'primeng/overlaypanel';
import { navItems } from '../../_nav';
import {ToastModule} from 'primeng/toast';
import { Message } from 'primeng/api';
import { MessageService } from 'primeng/api';

import { Subject, Observable } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  providers: [MessageService]
})
export class DefaultLayoutComponent {
  public sidebarMinimized = false;
  public navItems = navItems;

  public displaySidebar: boolean = false;
	public display: boolean = false;
	public checked: boolean = false;
	public checked2: boolean = false;
	public checked3: boolean = true;
	public checked4: boolean = false;
	public checked5: boolean = true;
	public checked6: boolean = false;
	public checked7: boolean = false;
	public checked8: boolean = true;
	public displaySearch: boolean = false;
	public makechanges: boolean = false;
	public user: any;
	public id: any;
	public idbase: any;
	public customers: any;
	public value: any;
	public custList: any;
	public countries = [];
	public searchText: any;
	search: boolean = false;
	public loading = false;
	public visibleSidebarContact: boolean = false;
	public noResults: boolean = false;
	public taskResults: boolean = false;

	public customerResults: boolean = false;
	events:any;

	complete: any;
	incomplete: any;



	firstName: string;
	lastName: string;
	street: string;
	city: string;
	state: string;
	secondary: string;
	fax: string;
	zipcode: string;
	fullAddress: string;
	phone: any;
	status: any;
	custType: any;
	source: any;
	email: any;
	customer: any;
	autoEnroll: any;
	public statuses: any;
	public types: any;
  public sources: any;
  employees:any;
  creator:any;
  public enrollOptions: any;
  searchChanged = new Subject<string>();
  
  constructor(@Inject(SESSION_STORAGE) 
  public storage: StorageService, 
  public messageService: MessageService, 
  private route: ActivatedRoute, 
  private router: Router, 
  private mainService: MainService, 
  public filter: FilterPipe) { 

	this.searchChanged.pipe(debounceTime(1000)).subscribe(() => {
		// this.searchResult$ = this.api.search(this.model);
		console.log(this.searchText);
		this.getItems(this.searchText);
	  });

  }

	ngOnInit() {
		this.creator = [];
		this.checkToken();
		this.initialize();
		this.getSchedule();
		this.getTasks();

		let user = this.storage.get('currentUser');
		this.creator.push(user._id);
		  let id = user.company._id;
		  let url = 'secureauth/getemployees/' + id;
		  this.mainService.getData(url).subscribe(res => {
			this.employees = res;
		  }, err => {
			console.log(err);
		  });
	}

	checkToken(){
		let value = this.mainService.isTokenExpired();
		if (value == true){
		  //Return to login
		  this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Token Expired, Please Login Again' });
		  this.storage.set('token', '');
		  this.storage.set('currentUser', '');
		  this.router.navigate(['/']);
		}
	  }

	changed() {
		this.searchChanged.next();
	  }

	initialize(){
		this.idbase = this.storage.get('currentUser');
	}

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }
  authorizedError(){
	this.messageService.add({severity:'error', summary: 'Error', detail:'Not Authorized'});
   }

	searchToggle() {
		this.initialize();
		let control = this.idbase.pageAccess.includes('VIEWCUSTOMER');
		console.log(control);
		if(control == true){
			this.fetchUser();
			this.displaySearch = !this.displaySearch;
		}else{
			this.authorizedError();
		}
	}

	createToggle() {
		this.initialize();
		if(this.idbase.pageAccess.includes("CREATECUSTOMER")){
			this.displaySearch = false;
			this.visibleSidebarContact = !this.visibleSidebarContact;
		}else{
			this.authorizedError();
		}
	}

	getTasks() {
		let id = this.storage.get('currentUser');
		this.incomplete = [];
		let url = 'tasks/get/user&' + id._id;
		this.mainService.getData(url).subscribe(res => {
	
		  for (var i = 0; i < res.length; i++) {
			if (res[i].completed == "No") {
			  this.incomplete.push(res[i])
			}
		  }
		  let results = res;
	
	
		  if (i == res.length) {
			if (this.incomplete.length == 0) {
			  this.taskResults = true;
			} else {
			  this.taskResults = false;
			}
		  }
		})
	  }

	  markNote(result) {
		let url = 'tasks/mark/' + result._id;
		this.mainService.editData(result, url).subscribe((result) => {
		  this.getTasks();
		}, (err) => {
		  console.log("An Error Occurred.");
		});
	  }

	logout() {
		this.storage.set('token', '');
		this.storage.set('currentUser', '');
		this.router.navigate(['/']);
	}
	fetchUser() {
		this.phone = "";
		this.secondary = "";
		this.email = "";
		this.statuses = [
			{ name: 'Active', value: 'Active' },
			{ name: 'Inactive', value: 'Inactive' },
			{ name: 'Prospective', value: 'Prospective' },
			{ name: 'Lost Prospect', value: 'Lost Prospect' },
		];
		this.types = [
			{ name: 'Residential', value: 'Residential' },
			{ name: 'Commercial', value: 'Commercial' },
		];
		this.sources = [
			{ name: 'Social Media/Online', value: 'Social Media/Online' },
			{ name: 'Referral', value: 'Referral' },
			{ name: 'Website', value: 'Website' },
			{ name: 'Other', value: 'Other' },
		];
		this.enrollOptions = [
			{ name: 'Yes', value: 'Yes' },
			{ name: 'No', value: 'No' },
		];
		this.autoEnroll = { name: 'No', value: 'No' };
		this.status = { name: 'Active', value: 'Active' };
		this.source = { name: 'Referral', value: 'Referral' };
    this.custType = { name: 'Residential', value: 'Residential' };
	}


	selectCustomer(rowData) {
		console.log(rowData._id);
		this.router.navigate(['/profile/' + rowData._id]);
		this.searchText = "";
		this.searchToggle();
		this.customers = [];
	}

	getItems(search){
		this.loading = true;
			console.log(this.searchText);
			let id = this.idbase.company._id;
			let link = 'customer/search/';
			let customer = {
				search: search,
				company: id,
		}
			this.mainService.createData(customer, link).subscribe((result) => {
				this.customers = result;
				this.loading = false;
				if(result.length == 0){
					this.customerResults = true;
				  }else{
					this.customerResults = false;
				  }
			}, (err) => {
				this.loading = false;
				console.log("An Error Occurred.")
			});
	}

	create() {
		if(this.phone != null){
			var p1 = this.phone.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")
		  }else{
			p1 = ""
		  }
		  if(this.secondary != null){
			var p2 = this.secondary.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")
		  }else{
			p2 = ""
		  }
		  if(this.fax != null){
			var p3 = this.fax.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")
		  }else{
			p3 = ""
		  }
		let customer = {
			firstName: this.firstName,
			lastName: this.lastName,
			street: this.street,
			city: this.city,
			state: this.state,
			zipcode: this.zipcode,
			fullAddress: this.street + " " + this.city + ", " + this.state + " " + this.zipcode,
			phone: p1,
			email: this.email,
			creator: this.creator,
			status: this.status.value,
			source: this.source.value,
			custType: this.custType.value,
			autoEnroll: this.autoEnroll.value,
			secondary: p2,
			fax: p3
    }
    let link = 'customer';
		this.mainService.createData(customer, link).subscribe((result) => {
			this.customer = result;
			let cid = this.customer._id;
			this.createToggle();
			this.firstName = "";
			this.lastName = "";
			this.street = "";
			this.city = "";
			this.state = "";
			this.zipcode = "";
			this.phone = "";
			this.email = "";
			this.secondary = "";
			this.fax = "";
			this.creator = this.creator;
			this.router.navigate(['/profile/' + this.customer._id]);
		}, (err) => {
			console.log("An Error Occurred.")
		});
	}

	async assignUser(result) {
        var n = this.creator.includes(result._id);
		if((n == true) && this.creator.length <= 1){
			this.messageService.add({ severity: 'error', summary: 'Error', detail: 'At Least One Account Manager Required!' });
			console.log("Not ENough Managers");
		  }
		  if ((n == true) && (this.creator.length > 1)) {
          var index = this.creator.indexOf(result._id);
          if (index > -1) {
			this.creator.splice(index, 1);
			this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Employee Removed' });
          }
		} 
		if (n == false) {
		  this.creator.push(result._id);
		  this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Employee Added' });
		}
		console.log(this.creator);
      }
	
	  async getSchedule(){
		let url = 'schedule/get/menu&' + this.idbase._id;
		this.mainService.getData(url).subscribe(res => {
		  let results = res;
		  this.events = res;
		  if(res.length == 0)
		  {
			this.noResults = true;
		  }else{
			this.noResults = false;
		  }
		 })
	  }
  
}
