import { Component, OnInit, Inject, Injectable, ViewChild } from '@angular/core';
import { MainService } from '../../main.service';
import { SESSION_STORAGE, StorageService } from 'angular-webstorage-service';
import { ActivatedRoute, Router } from '@angular/router';

import { ModalDirective } from 'ngx-bootstrap/modal';

import {ToastModule} from 'primeng/toast';
import { Message } from 'primeng/api';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'register.component.html',
  providers: [MessageService]
})
export class RegisterComponent implements OnInit {

  password:any;
  password1: any;
  firstName: string;
	lastName: string;
	street: string;
	city: string;
	state: string;
	title: string;
	zipcode: string;
	fullAddress: string;
	phone: any;
  email: string;
  payRate: any;
  
  companyName: string;
	cstreet: string;
	ccity: string;
	cstate: string;
	czipcode: string;
	cphone: string;
  cemail: string;
  website: any;
  license: any;
  

  public showAlert: boolean = true;
  public msgs: Message[] = [];
  public msgsGrowl: Message[] = [];

  @ViewChild('smallModal') public smallModal: ModalDirective;

  constructor(@Inject(SESSION_STORAGE) public storage: StorageService, public mainService: MainService, private route: ActivatedRoute, private router: Router, public messageService: MessageService) { }

  ngOnInit(): void{


  }

  register(){
    this.router.navigate(['/login']);
  }

  create() {
		if(this.phone != null){
			var p1 = this.phone.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")
		  }else{
			p1 = ""
		  }
		  if(this.cphone != null){
			var p2 = this.cphone.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")
		  }else{
			p2 = ""
      }
      if(this.payRate == null || this.payRate == undefined || this.payRate == ""){
        this.payRate = 0
        }
		let details = {
			firstName: this.firstName,
			lastName: this.lastName,
			street: this.street,
			city: this.city,
			state: this.state,
			zipcode: this.zipcode,
			fullAddress: this.street + " " + this.city + ", " + this.state + " " + this.zipcode,
			phone: p1,
      email: this.email,
      password: this.password,
      companyName: this.companyName,
			cstreet: this.street,
			ccity: this.city,
			cstate: this.state,
			czipcode: this.zipcode,
      cemail: this.email,
      payRate: this.payRate,
      cphone: p2,
      website: this.website,
      license: this.license
    }
    let link = 'auth/register';
    console.log(details);
		this.mainService.createData(details, link).subscribe((result) => {
      console.log(result);
      console.log(result.user);
      console.log(result.token);
      this.storage.set('email', this.email);
       this.storage.set('currentUser', result.user);
       this.storage.set('userid', result.user._id);
      this.storage.set('token', result.token);
      this.messageService.add({severity:'success', summary: 'Success', detail:'Account & Company Created!'});
      this.router.navigate(['/login']);
		}, (err) => {
      this.messageService.add({severity:'error', summary: 'An Error Occurred', detail:'Please Try Again.'});
      console.log(err);
      if(err.error.error == 'User already exists.'){
        this.smallModal.show();
        }
		});
	}

  cancel(){
    this.router.navigate(['/login']);
  }

}
