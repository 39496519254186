import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vault',
  templateUrl: './vault.component.html',
  styleUrls: ['./vault.component.css']
})
export class VaultComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
