import { Component, OnInit, Inject, Injectable, ViewChild, ElementRef, TemplateRef} from '@angular/core';
import { MainService } from '../../main.service';
import { SESSION_STORAGE, StorageService } from 'angular-webstorage-service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Routes, RouterModule, ActivatedRoute, Router, NavigationEnd } from '@angular/router';

import { ToastModule } from 'primeng/toast';
import { Message } from 'primeng/api';
import { MessageService } from 'primeng/api';
import * as moment from 'moment';


@Component({
  selector: 'files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.css']
})
export class FilesComponent implements OnInit {

  @ViewChild('files') private modalContent: TemplateRef<FilesComponent>
  private modalRef: NgbModalRef

  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;

  pdfSrc = "https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf";

  user:any;
  id:any;
  uid:any;

  public loading: boolean = false;

  constructor(private modalService: NgbModal, @Inject(SESSION_STORAGE) public storage: StorageService, public mainService: MainService, public messageService: MessageService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {

    this.user = this.storage.get('currentUser');
    this.id = this.storage.get('customer');
    this.uid = this.storage.get('addurl');
  }

  open(){
    this.ngOnInit();
    this.modalRef = this.modalService.open(this.modalContent,
      {
      size: 'sm'
      })
    this.modalRef.result.then()
  }

  close(){

    this.modalRef.close();
  }

  dismiss(){
    this.modalRef.dismiss()
  }


  imageClick() {
    const fileInput = document.getElementById('file-upload-input');
    fileInput.click();
  }

  fileInputChange(event: EventTarget) {
    let token = this.storage.get('token');
    this.loading = true;
    // const instance = this;
    // const fileInput = document.getElementById('file-upload-input');
    // let id = this.route.snapshot.params['id'];

    const eventObj: MSInputMethodContext = event as MSInputMethodContext;
    const target: HTMLInputElement = eventObj.target as HTMLInputElement;
    const file: File = target.files[0];
    // const url = 'http://localhost:8080/api/files/upload/' + this.uid;
    const url = 'https://kenectin.com/api/files/upload/' + this.uid;

    const ext = file.name.split('.').pop();
    let customer = this.id;
    let user = this.user.company._id;
    let time = moment().format("MMDDYYhhmmssa");
    let filename = customer + "-" + user + "-" + time + "." + ext;
    console.log(filename + ext)
    const formData = new FormData();
    formData.append('file', file, filename);
    fetch(url, {
      method: 'POST',
      headers: new Headers({
        'Authorization': 'Bearer ' + token
      }),
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        this.loading = false;
        this.messageService.add({ severity: 'success', summary: 'Upload Successful', detail: 'File Created' });
        this.dismiss();
      })
      .catch(error => {
        this.loading = false;
        console.error('error uploading image');
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Upload failed' });
      })
  }
  

}
