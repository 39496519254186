import { Component, OnInit, Inject, Injectable, ViewChild, ElementRef } from '@angular/core';
import { MainService } from '../../main.service';
import { SESSION_STORAGE, StorageService } from 'angular-webstorage-service';
import { Routes, RouterModule, ActivatedRoute, Router, NavigationEnd } from '@angular/router';

import { ToastModule } from 'primeng/toast';
import { Message } from 'primeng/api';
import { MessageService } from 'primeng/api';
import * as moment from 'moment';

import { ModalDirective } from 'ngx-bootstrap/modal';

// For Tab Modals
import { ContactsComponent } from '../contacts/contacts.component';
import { TmodalComponent } from '../tmodal/tmodal.component';
import { NotesComponent } from '../notes/notes.component';
import { FilesComponent } from '../files/files.component';
import { TicketsComponent } from '../tickets/tickets.component';
import { RemindersComponent } from '../reminders/reminders.component';
import { EventsComponent } from '../events/events.component';
import { TasksComponent } from '../tasks/tasks.component';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
  providers: [MessageService]
})
export class ProfileComponent implements OnInit {

  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;

  public id: any;
  public user: any;
  public customer: any;
  navigationSubscription: any;

  public result: any;
  public results: any;

  title1: any;
  title2: any;
  section: any;

  //Customer and Contact Variables
  firstName: string;
  lastName: string;
  street: string;
  city: string;
  state: string;
  secondary: string;
  fax: string;
  zipcode: string;
  fullAddress: string;
  phone: string;
  creator:any;
  public contType: any;
  public contTypes: any;
  email: string;
  public statuses: any;
  public types: any;
  public sources: any;
  public loading: boolean = false;

  public showButton: boolean = false;
  public showTabContainer: boolean = false;
  public showMainContainer: boolean = true;


  //Event Variables
  public showAllEvents: boolean = false;
  public upcoming: any;
  public previous: any;

  public noResults: boolean = false;
  public noResults1: boolean = false;
  public noResults2: boolean = false;


  //File Variables:
  public showFileUpload: boolean = false;
  public showAllFiles: boolean = false;
  public showOneFile: boolean = false;
  public showFile: boolean = false;
  public showPDF: boolean = false;
  public file: any;
  public files: any;
  fileurl:any;

  description:any;




  prime: any;
  alt: any;
  fx: any;

  userim: any;
  custim: any;
  esubj: any;
  emessage: any;
  contactphone: any;

  // Edit Customer Sidebar Controller
  public displaySidebar: boolean = false;

  @ViewChild('smallModal') public smallModal: ModalDirective;
  @ViewChild('largeModal') public largeModal: ModalDirective;
  @ViewChild('eventModal') public eventModal: ModalDirective;

  @ViewChild('filedeleteModal') public filedeleteModal: ModalDirective;

  // For Tabs
  @ViewChild('contacts') private contactsModal: ContactsComponent;
  @ViewChild('tmodal') private tModal: TmodalComponent;
  @ViewChild('notes') private notesModal: NotesComponent;
  @ViewChild('files') private filesModal: FilesComponent;
  @ViewChild('tickets') private ticketsModal: TicketsComponent;
  @ViewChild('reminders') private remindersModal: RemindersComponent;
  @ViewChild('events') private eventsModal: EventsComponent;
  @ViewChild('tasks') private tasksModal: TasksComponent;


  //For Acct Pie Chart
  // Pie
  public pieChartLabels: string[] = ['Revenue', 'Expenses', 'Net Profit'];
  public pieChartData: number[] = [12000, 3400, 8600];
  public pieChartType = 'pie';


  public status: any;
  public source: any;
  public custType: any;
  employees:any;

  constructor(
    @Inject(SESSION_STORAGE) public storage: StorageService,
    public mainService: MainService,
    private route: ActivatedRoute,
    private router: Router,
    public messageService: MessageService,
  ) {
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        this.initialiseInvites();
      }
    });
  }

  ngOnInit(): void {
    this.creator = [];
    this.checkToken();
    this.user = this.storage.get('currentUser');
    let user = this.storage.get('currentUser');
    if (user.pageAccess.includes("VIEWCUSTOMER") == true) {
      this.initalizeProfile();
      let id = user.company._id;
      let url = 'secureauth/getemployees/' + id;
      this.mainService.getData(url).subscribe(res => {
        this.employees = res;
      }, err => {
        console.log(err);
      });
    } else {
      this.router.navigate(['/home']);
    }
    if (user.pageAccess.includes("VIEWFILE") == true) {
      this.showAllFiles = true;
    }
  }

  checkToken(){
    let value = this.mainService.isTokenExpired();
    if (value == true){
      //Return to login
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Token Expired, Please Login Again' });
      this.storage.set('token', '');
      this.storage.set('currentUser', '');
      this.router.navigate(['/']);
    }
  }

  initalizeProfile() {

    this.statuses = [
      { name: 'Active', value: 'Active' },
      { name: 'Inactive', value: 'Inactive' },
      { name: 'Prospective', value: 'Prospective' },
      { name: 'Lost Prospect', value: 'Lost Prospect' }
    ];
    this.types = [
      { name: 'Residential', value: 'Residential' },
      { name: 'Commercial', value: 'Commercial' }
    ];
    this.sources = [
      { name: 'Social Media/Online', value: 'Social Media/Online' },
      { name: 'Referral', value: 'Referral' },
      { name: 'Website', value: 'Website' },
      { name: 'Other', value: 'Other' }
    ];

    this.id = this.route.snapshot.params['id'];
    let id = this.route.snapshot.params['id'];
    let url = 'customer/select/' + id
    this.mainService.getData(url).subscribe(res => {
      this.customer = res;
      console.log(res);
      this.custim = res.email;

      if (res.phone != null) {
        this.prime = res.phone.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")
      }
      if (res.secondary != null) {
        this.alt = res.secondary.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")
      }
      if (res.fax != null) {
        this.fx = res.fax.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")
      }

      this.results = res.files;
      if(res.files.length == 0){
        this.noResults = true;
      }else{
        this.noResults = false;
      }

      this.status = { name: res.status, value: res.status };
      this.source = { name: res.source, value: res.source };
      this.custType = { name: res.custType, value: res.custType };
    }, err => {
      console.log(err);
    });

  }

  initialiseInvites() {
    this.closeTabs();
    this.showButton = false;
    this.showTabContainer = false;
    this.secondary = "";
    this.ngOnInit();
  }

  ngOnDestroy() {
    // avoid memory leaks here by cleaning up after ourselves. If we  
    // don't then we will continue to run our initialiseInvites()   
    // method on every navigationEnd event.
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  // events
  public chartClicked(e: any): void {
    console.log(e);
  }

  public chartHovered(e: any): void {
    console.log(e);
  }


  editCustomer(customer) {

    if (customer.phone != null) {
      customer.phone = customer.phone.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")
    } else {
      customer.phone = ""
    }
    if (customer.secondary != null) {
      customer.secondary = customer.secondary.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")
    } else {
      customer.secondary = ""
    }
    if (customer.fax != null) {
      customer.fax = customer.fax.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")
    } else {
      customer.fax = ""
    }

    customer.fullAddress = customer.street + " " + customer.city + ", " + customer.state + " " + customer.zipcode;
    customer.creator = this.creator;

    console.log(customer.phone + "  " + customer.secondary)


    this.customer.status = this.status.value;
    this.customer.source = this.source.value;
    this.customer.custType = this.custType.value;
    let id = this.route.snapshot.params['id'];
    let url = 'customer/edit/' + id;
    this.mainService.editData(customer, url).subscribe((result) => {
      this.customer = result;
      this.initalizeProfile();
      console.log(result);
      this.successMessage();
      this.displaySidebar = false;
    }, (err) => {
      console.log("An Error Occurred.");
      this.errorMessage();
    });
  }

  async assignUser(result) {
    var n = this.creator.includes(result._id);
    if((n == true) && this.creator.length <= 1){
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'At Least One Account Manager Required!' });
      console.log("Not ENough Managers");
    }
    if ((n == true) && (this.creator.length > 1)) {
      var index = this.creator.indexOf(result._id);
      console.log("More than 1 User");
      if (index > -1) {
        this.creator.splice(index, 1);
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Employee Removed' });
      }
    } 
    if (n == false) {
      this.creator.push(result._id);
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Employee Added' });
  
    }
    console.log(this.creator);
  }

  authorizedError() {
    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Not Authorized' });
  }

  editToggle() {
    this.creator = []; 
    if (this.user.pageAccess.includes("EDITCUSTOMER")) {
      this.displaySidebar = !this.displaySidebar;
      for (var i = 0; i < this.customer.creator.length; i++) {
        console.log([i] + " " + this.customer.creator[i]._id);
        this.creator.push(this.customer.creator[i]._id);
      }
      console.log(this.creator);
    } else {
      this.authorizedError();
    }
  }

  deleteCustomer() {
    if (this.user.pageAccess.includes("DELETECUSTOMER") == true) {
      const id = this.route.snapshot.params['id'];
      let url = 'customer/delete/' + id;
      this.mainService.deleteData(url).subscribe(res => {
        this.successMessage();
        this.router.navigate(['/home']);
      }, (err) => {
        console.log("An Error Occurred.");
        this.errorMessage();
      });
    } else {
      this.authorizedError();
      this.smallModal.hide();
    }
  }

  sendEmail() {
    if (this.user.pageAccess.includes("EMAILCUSTOMER")) {
      let expense = {
        userim: this.user.email,
        custim: this.custim,
        esubj: this.esubj,
        emessage: this.emessage,
      };

      let url = 'customer/customeremail/'

      this.mainService.createData(expense, url).subscribe((result) => {
        this.userim = "";
        this.custim = "";
        this.esubj = "";
        this.emessage = "";
        this.successMessage();
        this.largeModal.hide();
      }, (err) => {
        this.errorMessage();
        console.error('error');
      })
    } else {
      this.authorizedError();
      this.largeModal.hide()
    }
  }


  //Begin Tab Section Functions

  successMessage() {
    this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Request Complete' });
  }

  errorMessage() {
    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please Try Again' });
  }

  closeTabs() {
    this.showMainContainer = true;
    this.showButton = false;
    this.showTabContainer = false;
    this.secondary = "";

    this.showFileUpload = false;
    this.showAllFiles = false;

    this.showAllEvents = false;


  }


  // Begin Tab Loader

  // Contact Modal
  async openContactModal() {
    let id = this.route.snapshot.params['id'];
    this.storage.set('customer', id);
    return this.contactsModal.open();
  }

  // Contact Modal
  async openNoteModal() {
    let id = this.route.snapshot.params['id'];
    this.storage.set('customer', id);
    this.storage.set('project', null);
    this.storage.set('geturl', 'notes/get/customer&' + id);
    return this.notesModal.open();
  }

  async openFileModal() {
    let id = this.route.snapshot.params['id'];
    this.storage.set('customer', id);
    this.storage.set('addurl', 'customer&' + id);
    return this.filesModal.open();
  }

  async openTaskModal() {
    let id = this.route.snapshot.params['id'];
    this.storage.set('customer', id);
    this.storage.set('project', null);
    this.storage.set('event', null);
    this.storage.set('user', this.user);
    this.storage.set('geturl', 'tasks/get/customer&' + id);
    return this.tasksModal.open();
  }

  async openEventCreate() {
    let id = this.route.snapshot.params['id'];

    var options = {
      type: "Create",
      url: 'schedule/create',
      CustID: this.route.snapshot.params['id'],
      EstimateID: null,
      ProjectID: null,
      address: this.customer.fullAddress,
      equipment: null,
      viewtype: "Create"
    }
    return this.eventsModal.open(options);
  }

  async openEventGet() {
    let id = this.route.snapshot.params['id'];

    var options = {
      type: "Get",
      url: 'schedule/get/customer&' + this.route.snapshot.params['id'],
      CustID: this.route.snapshot.params['id'],
      EstimateID: null,
      ProjectID: null,
      viewtype: "Create"
    }
    return this.eventsModal.open(options);
  }




  //Begin File Section

  imageClick() {
    const fileInput = document.getElementById('file-upload-input');
    fileInput.click();
  }

  fileInputChange(event: EventTarget) {
    let token = this.storage.get('token');
    this.loading = true;

    let id = this.route.snapshot.params['id'];

    const eventObj: MSInputMethodContext = event as MSInputMethodContext;
    const target: HTMLInputElement = eventObj.target as HTMLInputElement;
    const file: File = target.files[0];
    const url = 'https://kenectin.com/api/files/upload/customer&' + id;

    const ext = file.name.split('.').pop();
    let customer = this.id;
    let user = this.user.company._id;
    let time = moment().format("MMDDYYhhmmssa");
    let filename = customer + "-" + user + "-" + time + "." + ext;
    console.log(filename + ext)
    const formData = new FormData();
    formData.append('file', file, filename);
    fetch(url, {
      method: 'POST',
      headers: new Headers({
        'Authorization': 'Bearer ' + token
      }),
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        this.loading = false;
        this.messageService.add({ severity: 'success', summary: 'Upload Successful', detail: 'File Created' });
        this.initalizeProfile();
      })
      .catch(error => {
        this.loading = false;
        console.error('error uploading image');
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Upload failed' });
      })
  }

  openFile(item){
    this.showFile = false;
    this.showPDF = false;
    this.showOneFile = true;
    this.result = item;
    this.fileurl = item.url;
    this.description = item.description;
    console.log(item.url);
    console.log("Result " + this.result);
    if (item.format == 'application/pdf'){
      this.showPDF = true;
      console.log(item.format == 'application/pdf');
    }else{
      this.showFile = true;
    }
  }

  downloadFile() {
    window.open(this.fileurl);
    this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Download Started' });
}


  editFile(){
    if(this.user.pageAccess.includes("EDITFILE") == true){
      for (var i = 0; i < this.customer.files.length; i++) {
        if (this.result.fid == this.customer.files[i].fid){
          this.customer.files[i].description = this.description;
          this.saveFile();
      }
    }
      }else{
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Not Authorized' });
      }
  }
  
  deleteFile(){
    if(this.user.pageAccess.includes("DELETEFILE") == true){
    for (var i = 0; i < this.customer.files.length; i++) {
      if (this.result.fid == this.customer.files[i].fid){
        this.customer.files.splice(i, 1);
        this.saveFile();
        this.showOneFile = false;
    }
  }
  }else{
    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Not Authorized' });
  }
  }
  
  
  saveFile(){
    this.showButton = false;
    let id = this.route.snapshot.params['id']; 
    let url = 'customer/edit/' + id;
  this.mainService.editData(this.customer, url).subscribe((result) => {
    this.customer = result;
    this.messageService.add({ severity: 'success', summary: 'Upload Successful', detail: 'File Updated' });
     this.initalizeProfile();
     this.filedeleteModal.hide();
  }, (err) => {
    console.log("An Error Occurred.");
    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Request Failed' });
  });
  }



  //End File Section


}
