import { Component, OnInit, Inject, Injectable, ViewChild } from '@angular/core';
import { SESSION_STORAGE, StorageService } from 'angular-webstorage-service';
import { ActivatedRoute, Router } from '@angular/router';
import {ModalDirective} from 'ngx-bootstrap/modal';
import { MainService } from '../../main.service';

import {ToastModule} from 'primeng/toast';
import { Message } from 'primeng/api';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css'],
  providers: [MessageService]
})
export class LandingComponent implements OnInit {
  @ViewChild('myModal') public myModal: ModalDirective;

  public showAlert: boolean = true;
  public msgs: Message[] = [];
  public msgsGrowl: Message[] = [];


  email: string;
  password: string;
  loading: any;
  token: any;
  user: any;
  public countries = [];
  public customers: any;
  tid: any;
  cid:any;

  constructor(@Inject(SESSION_STORAGE) public storage: StorageService, private route: ActivatedRoute, private router: Router, public messageService: MessageService, public mainService: MainService) { 
  }

  ngOnInit(): void {

    this.token = this.storage.get('email');

    if(this.token != null){
      this.email = this.token;
    }else{
      this.email = "";
    }
  }

  facebook(){
    window.open('https://www.facebook.com/Get-Kenect-383578519152171', "_self")
  }

  login() {

    let credentials = {
      email: this.email,
      password: this.password
    };
    let cred = this.email;
    this.storage.set('email', cred);
    let link = "auth/login";
    console.log(credentials);
    this.mainService.createData(credentials, link).subscribe((result) => {
      console.log(cred);
      console.log(result);
      console.log(result.user);
      console.log(result.token);
       this.storage.set('currentUser', result.user);
       this.storage.set('userid', result.user._id);
      this.storage.set('token', result.token);
      this.router.navigate(['/home']);
    }, (err) => {
    this.messageService.add({severity:'error', summary: 'Invalid Credentials', detail:'Please Try Again.'});
    this.password = "";
    if(this.token != null){
     this.email = this.token;
    }else{
     this.email = "";
    }
      console.log(err);
    });
    
    
    }

    register(){
      this.router.navigate(['/register']);
    }

  showLogin(){
    this.myModal.show();
  }
  hideLogin(){
    this.myModal.hide();
  }

}
