import { Component, OnInit, Inject, Injectable, ViewChild, ElementRef, TemplateRef} from '@angular/core';
import { MainService } from '../../main.service';
import { SESSION_STORAGE, StorageService } from 'angular-webstorage-service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { ToastModule } from 'primeng/toast';
import { Message } from 'primeng/api';
import { MessageService } from 'primeng/api';
import * as moment from 'moment';

@Component({
  selector: 'notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.css']
})
export class NotesComponent implements OnInit {

  @ViewChild('notes') private modalContent: TemplateRef<NotesComponent>
  private modalRef: NgbModalRef

  public showInitial: boolean = true;
  public id: any;
  public user: any;

  cid:any;
  pid:any;


  public result: any;
  public results: any;

  public showButton: boolean = false;
  public noResults: boolean = false;



    // Note Variables
    title: any;
    note: any;
    noteType: any;
    noteTypes: any;
    public showNoteCreate: boolean = false;
    public showAllNotes: boolean = false;
    public showOneNote: boolean = false;
    public showNoteEdit: boolean = false;
    public showNoteDel: boolean = false;

  constructor(private modalService: NgbModal, @Inject(SESSION_STORAGE) public storage: StorageService, public mainService: MainService, public messageService: MessageService) { }

  ngOnInit(): void {
    this.initializePage();
  }

  initializePage(){
    this.user = this.storage.get('currentUser');
    this.id = this.storage.get('customer');

    this.cid = this.storage.get('customer');
    this.pid = this.storage.get('project');

    this.noteTypes = [
      { name: 'Customer Interaction', value: 'Customer Interaction' },
      { name: 'General', value: 'General' },
      { name: 'Follow-Up Required', value: 'Follow-Up Required' }
    ];
    this.showNoteCreate = false;
    this.showAllNotes = false;
    this.showOneNote = false;
    this.showNoteEdit = false;
    this.showNoteDel = false;
    this.showInitial = true;
    this.showButton = false;
    this.results = [];
    this.result= "";
  }

  //View Holders

  retrieveMain() {
    this.showNoteMain();
    this.showNoteCreate = false;
    this.showAllNotes = true;
    this.showOneNote = false;
    this.showNoteEdit = false;
    this.showNoteDel = false;
    this.showInitial = false;
    this.showButton = false;
  }

  retrieveCreate() {
    if (this.user.pageAccess.includes("CREATENOTE")) {
      this.showNoteCreate = true;
      this.showAllNotes = false;
      this.showOneNote = false;
      this.showNoteEdit = false;
      this.showNoteDel = false;
      this.showInitial = false;
      this.showButton = true;
      this.title = "";
      this.note = "";
      this.noteType = { name: "", value: "" };
    } else {
      this.authorizedError();
    }
  }

  retrieveEdit() {
    if (this.user.pageAccess.includes("EDITNOTE")) {
      this.showNoteEdit = true;
      this.showNoteCreate = false;
      this.showAllNotes = false;
      this.showOneNote = false;
      this.showNoteEdit = true;
      this.showNoteDel = false;
      this.showButton = true;
    } else {
      this.authorizedError();
    }
  }

  retrieveEdit1(item) {
    if (this.user.pageAccess.includes("EDITNOTE")) {
      this.result = item;
      this.showNoteEdit = true;
      this.showNoteCreate = false;
      this.showAllNotes = false;
      this.showOneNote = false;
      this.showNoteEdit = true;
      this.showNoteDel = false;
      this.showButton = true;
      this.noteType = { name: item.noteType, value: item.noteType };
    } else {
      this.authorizedError();
    }
  }

  open(){
    this.modalRef = this.modalService.open(this.modalContent,
      {
      size: 'lg'
      });
    this.modalRef.result.then()
    this.ngOnInit();
  }

  close(){
    this.modalRef.close();
    this.storage.set('customer', "");
    this.storage.set('geturl', "");
    this.showNoteCreate = false;
    this.showAllNotes = false;
    this.showOneNote = false;
    this.showNoteEdit = false;
    this.showNoteDel = false;
    this.showInitial = true;
    this.showButton = false;
    this.results = [];
    this.result= "";
  }

  dismiss(){
    this.modalRef.dismiss();
    this.storage.set('customer', "");
    this.storage.set('geturl', "");
    this.showNoteCreate = false;
    this.showAllNotes = false;
    this.showOneNote = false;
    this.showNoteEdit = false;
    this.showNoteDel = false;
    this.showInitial = true;
    this.showButton = false;
    this.results = [];
    this.result= "";
  }

  successMessage() {
    this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Request Complete' });
  }

  errorMessage() {
    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please Try Again' });
  }

  authorizedError() {
    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Not Authorized' });
  }

  closeTabs() {
    this.showButton = false;
    this.showNoteCreate = false;
    this.showAllNotes = false;
    this.showOneNote = false;
    this.showNoteEdit = false;
    this.showButton = false;
  }



  //Note Main Controllers
  async createNewNote() {
    this.showButton = false;
    let details = {
      title: this.title,
      note: this.note,
      customerid: this.cid,
      projectid: this.pid,
      noteType: this.noteType.value,
    };
    let url = 'notes/create/';
    this.mainService.createData(details, url).subscribe((result) => {
      this.successMessage();
      this.showSelectNote(result._id);
      this.title = "";
      this.note = "";
    }, (err) => {
      this.showButton = true;
      this.errorMessage();
    });
  }

  editNote(result) {
    this.showButton = false;
    this.result.noteType = this.noteType.value;
    let url = 'notes/edit/' + result._id;
    this.mainService.editData(result, url).subscribe((result) => {
      this.showSelectNote(result._id);
      this.successMessage();
      console.log(result);
    }, (err) => {
      this.showButton = true;
      console.log("An Error Occurred.");
      this.errorMessage();
    });
  }

  showSelectNote(id) {
    //Check Permissions
    if (this.user.pageAccess.includes("VIEWNOTE")) {
      this.showNoteCreate = false;
      this.showAllNotes = false;
      this.showNoteEdit = false;
      this.showNoteDel = false;
      this.showButton = false;
      this.showOneNote = true;
      let url = 'notes/get/getone&' + id;
      this.mainService.getData(url).subscribe(res => {
        this.result = res;
        this.noteType = { name: res.noteType, value: res.noteType };
      })
    } else {
      this.authorizedError();
      this.showOneNote = false;
    }
  }

  showNoteMain() {
    //Check Permissions
    if (this.user.pageAccess.includes("VIEWNOTE")) {
      this.showAllNotes = true;
      let url = this.storage.get('geturl');
      // let url = 'notes/viewcustomers/' + this.id;
      this.mainService.getData(url).subscribe(res => {
        this.results = res;
        if (res.length == 0) {
          this.noResults = true;
        } else {
          this.noResults = false;
        }
      })
    } else {
      this.authorizedError();
      this.showAllNotes = false;
    }
  }

  showNoteDelete(item) {
    //Check Permissions
    if (this.user.pageAccess.includes("DELETENOTE")) {
      this.result = item;
      this.showNoteDel = true;
    } else {
      this.authorizedError();
    }
  }

  hideNoteDelete(){
    this.showNoteDel = false;
  }


  // Delete Item and Return to View All
  deleteNote(id) {
    let url = 'notes/delete/' + id;
    this.mainService.deleteData(url).subscribe(res => {
      this.successMessage();
      this.retrieveMain();
      this.showNoteDel = false;
    }, (err) => {
      console.log("An Error Occurred.");
      this.errorMessage();
    });
  }

}
