import { Component, OnInit, Inject, Injectable, ViewChild, ElementRef, TemplateRef} from '@angular/core';
import { MainService } from '../../main.service';
import { SESSION_STORAGE, StorageService } from 'angular-webstorage-service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { ToastModule } from 'primeng/toast';
import { Message } from 'primeng/api';
import { MessageService } from 'primeng/api';
import * as moment from 'moment';

import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'tmodal',
  templateUrl: './tmodal.component.html',
  styleUrls: ['./tmodal.component.css']
})
export class TmodalComponent implements OnInit {
  @ViewChild('tmodal') private modalContent: TemplateRef<TmodalComponent>
  private modalRef: NgbModalRef

  public id: any;
  public user: any;
  public customer: any;
  navigationSubscription: any;

  public result: any;
  public results: any;

  @ViewChild('smallModal') public smallModal: ModalDirective;
  public showButton: boolean = false;

  constructor(private modalService: NgbModal, @Inject(SESSION_STORAGE) public storage: StorageService, public mainService: MainService, public messageService: MessageService) { }

  ngOnInit(): void {
    this.initializePage();
    }
  
    initializePage(){
      this.user = this.storage.get('currentUser');
      let user = this.storage.get('currentUser');
  
    }
  
    closeTabs() {

    }

      //Begin Tab Section Functions

  successMessage() {
    this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Request Complete' });
  }

  errorMessage() {
    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please Try Again' });
  }

  authorizedError() {
    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Not Authorized' });
  }

  open(){
    this.modalRef = this.modalService.open(this.modalContent)
    this.modalRef.result.then()
  }

  close(){
    this.modalRef.close()
  }

  dismiss(){
    this.modalRef.dismiss()
  }

}
