import { Component, OnInit, Inject, Injectable, ViewChild, ElementRef, TemplateRef } from '@angular/core';
import { MainService } from '../../main.service';
import { SESSION_STORAGE, StorageService } from 'angular-webstorage-service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { ToastModule } from 'primeng/toast';
import { Message } from 'primeng/api';
import { MessageService } from 'primeng/api';
import * as moment from 'moment';


@Component({
  selector: 'tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.css']
})
export class TasksComponent implements OnInit {

  user: any;
  complete: any;
  comprev:any;
  incomprev:any;
  incomplete: any;
  hidden: any;
  uid: any;
  eid: any;
  cid: any;
  pid: any;
  geturl: any;
  task: any;

  employees: any;
  assignee: any;
  selected: any;
  dateDue: any;

  public showButton: boolean = true;
  public noResults: boolean = false;
  public noResults1: boolean = false;
  public assignTasks: boolean = false;

  @ViewChild('tasks') private modalContent: TemplateRef<TasksComponent>
  private modalRef: NgbModalRef

  constructor(private modalService: NgbModal, @Inject(SESSION_STORAGE) public storage: StorageService, public mainService: MainService, public messageService: MessageService) { }

  ngOnInit(): void {
    this.initializePage();
  }

     initializePage(){
      this.complete = [];
      this.incomplete = [];
  
      this.cid = this.storage.get('customer');
      this.pid = this.storage.get('project');
      this.eid = this.storage.get('event');
      this.uid = this.storage.get('user');
      this.geturl = this.storage.get('geturl');
      let user = this.storage.get('user');
      console.log(user);
      this.assignee = { name: user.fullName, value: user._id };
      this.selected = { name: user.fullName, value: user._id };

      if (this.uid.pageAccess.includes("ASSIGNTASK") == true) {
      let id = this.uid.company._id;
      this.assignTasks = true;
      let url = 'secureauth/getemployees/' + id;
      this.mainService.getData(url).subscribe(res => {
        this.employees = [];
        for (var i = 0; i < res.length; i++) {
          this.employees.push({name: res[i].fullName, value: res[i]._id});
          }
      }, err => {
        console.log(err);
      });
    }

      this.getTasks();
    }

  open() {
    this.modalRef = this.modalService.open(this.modalContent,
      {
        size: 'xl'
      });
    this.modalRef.result.then()
    this.ngOnInit();
  }

  close() {
    this.modalRef.close()
  }

  dismiss() {
    this.modalRef.dismiss()
  }

  getTasks() {
    console.log('hola');
    this.complete = [];
    this.incomplete = [];
    this.incomprev = [];
    this.comprev = [];
    this.hidden = [];
    let incomp = 0;
    let comp = 0;
    this.mainService.getData(this.geturl).subscribe(res => {

      for (var i = 0; i < res.length; i++) {
        if (res[i].completed == "Yes" && res[i].hidden == "No") {
          comp++;
          this.complete.push(res[i]);
        }
        if (res[i].completed == "No") {
          incomp++;
          this.incomplete.push(res[i]);
        }
        if(res[i].completed == "Yes" && res[i].hidden == "Yes"){
          this.hidden.push(res[i]);
        }
      }

      this.complete.sort(function(a,b){
        return b.completeHolder-a.completeHolder
      })

      this.incomplete.sort(function(a,b){
        return a.dateHolder-b.dateHolder
      })

      for (var i = 0; i < this.complete.length; i++) {
        if (i<16) {
          comp++;
          this.comprev.push(this.complete[i]);
        }
      }

      for (var i = 0; i < this.incomplete.length; i++) {
        if (i<16) {
          incomp++;
          this.incomprev.push(this.incomplete[i]);
        }
      }
      let results = res;


      if (i == res.length) {
        if (this.complete.length == 0) {
          this.noResults = true;
        } else {
          this.noResults = false;
        }

        if (this.incomplete.length == 0) {
          this.noResults1 = true;
        } else {
          this.noResults1 = false;
        }
      }
    })
  }

  showallComplete(){
    this.comprev = this.complete;
  }

  showHidden(){
    this.comprev = this.hidden;
  }

  showallIncomplete(){
    this.incomprev = this.incomplete;
  }

  getEmployeeTasks() {
    this.complete = [];
    this.incomplete = [];
    let url = 'tasks/get/user&' + this.selected.value;
    this.mainService.getData(url).subscribe(res => {

      for (var i = 0; i < res.length; i++) {
        if (res[i].completed == "Yes") {
          this.complete.push(res[i]);
          this.comprev.push(res[i])
        }
        if (res[i].completed == "No") {
          this.incomplete.push(res[i]);
          this.incomprev.push(res[i])
        }
        if (res[i].hidden == "Yes") {
          this.hidden.push(res[i]);
        }
      }
      let results = res;


      if (i == res.length) {
        if (this.complete.length == 0) {
          this.noResults = true;
        } else {
          this.noResults = false;
        }

        if (this.incomplete.length == 0) {
          this.noResults1 = true;
        } else {
          this.noResults1 = false;
        }
      }
    })
  }

  async createNew() {
    console.log(this,this.dateDue);
    this.showButton = false;
    let details = {
      task: this.task,
      projectID: this.pid,
      customerID: this.cid,
      eventID: this.eid,
      userID: this.assignee.value,
      owner: this.uid.company._id,
      creator: this.uid.fullName,
      dateDue: this.dateDue
    };
    let url = 'tasks/create/';
    this.mainService.createData(details, url).subscribe((result) => {
      this.successMessage();
      this.getTasks();
      this.task = "";
      this.dateDue = "";
      this.showButton = true;
    }, (err) => {
      this.showButton = true;
      this.errorMessage();
    });
  }

  markNote(result) {
    this.showButton = false;
    let url = 'tasks/mark/' + result._id;
    this.mainService.editData(result, url).subscribe((result) => {
      this.getTasks();
      this.successMessage();
    }, (err) => {
      this.showButton = true;
      console.log("An Error Occurred.");
      this.errorMessage();
    });
  }

  hideNote(result) {
    let url = 'tasks/hide/' + result._id;
    this.mainService.editData(result, url).subscribe((result) => {
      this.getTasks();
      this.successMessage();
      console.log(result);
    }, (err) => {
      console.log("An Error Occurred.");
      this.errorMessage();
    });
  }

  successMessage() {
    this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Request Complete' });
  }

  errorMessage() {
    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please Try Again' });
  }

  authorizedError() {
    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Not Authorized' });
  }

}
