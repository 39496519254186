import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs/';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { from } from 'rxjs';
import { SESSION_STORAGE, StorageService } from 'angular-webstorage-service';
import jwt_decode from "jwt-decode";


const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

// const url = "http://localhost:8080/api/";
// const url = "https://kenectwork.com/api/";
const url = "https://kenectin.com/api/";


@Injectable({
  providedIn: 'root'
})
export class MainService {

  public key: any;
  public data: any;
  public company: any;
  public currentUser: any;
  public item: any;
  user: any;
  public id: any;
  headers: any;
  decoded: any;

  constructor(
    @Inject(SESSION_STORAGE) private storage: StorageService,
    public http: HttpClient
  ) {

  }



  private extractData(res: Response) {
    let body = res;
    return body || {};
  }

  logout() {
    this.storage.set('token', '');
  }

  getData(link): Observable<any> {
    return this.http.get(url + link).pipe(
      map(this.extractData));
  }

  createData(passed, link): Observable<any> {

    let converted = JSON.stringify(passed);

    let dest = url + link;

    return this.http.post<any>(dest, converted, httpOptions).pipe(
      tap((response) => console.log(`customer created in w/ id=${response}`))
    );
  }

  editData(passed, link): Observable<any> {

    let converted = JSON.stringify(passed);

    let dest = url + link;

    return this.http.put<any>(dest, converted, httpOptions).pipe(
      tap((response) => console.log(`customer created in w/ id=${response}`))
    );
  }

  deleteData(link): Observable<any> {

    let dest = url + link;

    return this.http.delete<any>(dest, httpOptions).pipe(
    );
  }

  // Check Token Validity

  getTokenExpirationDate(token: string): Date {
    this.decoded = jwt_decode(token);

    if (this.decoded.exp === undefined) return null;

    const date = new Date(0);
    date.setUTCSeconds(this.decoded.exp);
    return date;
  }

  isTokenExpired(token?: string): boolean {
    if (!token) token = this.getToken();
    if (!token) return true;

    const date = this.getTokenExpirationDate(token);
    if (date === undefined) return false;
    return !(date.valueOf() > new Date().valueOf());
  }

  getToken(): string {
    let token = this.storage.get('token');
    return token;
  }
}
