import { Component, OnInit, Inject, Injectable, ViewChild, ElementRef} from '@angular/core';
import { MainService } from '../../main.service';
import { SESSION_STORAGE, StorageService } from 'angular-webstorage-service';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';

import { ToastModule } from 'primeng/toast';
import { Message } from 'primeng/api';
import { MessageService } from 'primeng/api';

import { ModalDirective } from 'ngx-bootstrap/modal';

declare var H: any;

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css'],
  providers: [MessageService]
})
export class CalendarComponent implements OnInit {

  @ViewChild('smallModal') public smallModal: ModalDirective;
  @ViewChild('updateModal') public updateModal: ModalDirective;

  @ViewChild('filterModal') public filterModal: ModalDirective;
  @ViewChild('rangeModal') public rangeModal: ModalDirective;

  //Maps 
  zoom = 10;
  center: google.maps.LatLngLiteral
  options: google.maps.MapOptions = {
    mapTypeId: 'roadmap',
    zoomControl: true,
    scrollwheel: true,
    disableDoubleClickZoom: true,
    maxZoom: 15,
    minZoom: 8,
  }
  markers = [];
  types: any;

  eventdetails: any;
  public noResults: boolean = false;
  employees: any;

  selected: any;
  user: any;
  results: any;
  month: any;
  count: any;
  display: any;
  map: any;
  ui:any;

  startDate: any;
  endDate: any;

  currentFilter: any;
  currentFilterID:any;
  typeFilter:any;
  typeVariable: any;

  private platform: any;

  @ViewChild("map", { static: true}) public mapElement: ElementRef;

  constructor(@Inject(SESSION_STORAGE) public storage: StorageService, public mainService: MainService, private route: ActivatedRoute, private router: Router, public messageService: MessageService) { 
  //   this.platform = new H.service.Platform({
  //     "apikey": "4gLR9jN_H9VQb91ucoFRwtzVZQ63l5rKvWBcLUoWRCg"
  // });
  }

  ngOnInit(): void {
    navigator.geolocation.getCurrentPosition((position) => {
      this.center = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      }
      console.log(position);
      console.log(this.center);


      // let defaultLayers = this.platform.createDefaultLayers();

      // this.map = new H.Map(
      //     this.mapElement.nativeElement,
      //     defaultLayers.vector.normal.map,
      //     {
      //         zoom: 10,
      //         center: { lat: position.coords.latitude, lng: position.coords.longitude }
      //     }
      // );

    })
    this.checkToken();
    this.initialize();
    this.typeVariable = null;
    this.currentFilter = null;
    this.currentFilterID = null;

    
  }

  checkToken(){
    let value = this.mainService.isTokenExpired();
    console.log("Token Validity " + value);
    if (value == true){
      //Return to login
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Token Expired, Please Login Again' });
      this.storage.set('token', '');
      this.storage.set('currentUser', '');
      this.router.navigate(['/']);
    }
  }

  initialize() {
    this.types = [
      { name: 'Service', value: 'Service' },
      { name: 'Estimate', value: 'Estimate' }
    ];
    this.user = this.storage.get('currentUser');
    let idbase = this.storage.get('currentUser');
    if(idbase.pageAccess.includes("VIEWEVENT")){
      let url = 'schedule/calendar/' + this.user.company._id;
      let input = moment().format("MM/DD/YYYY");
      this.display = input;
      let details = {
        inputDate: input,
      };
      this.getEmployees();
      this.mainService.createData(details, url).subscribe((result) => {
        this.successMessage();
        this.results = result;
        this.setMarkers(result);
        this.count = result.length;
        if(result.length == 0){
          this.noResults = true;
        }else{
          this.noResults = false;
        }
      }, (err) => {
        this.errorMessage();
      });
		}else{
			this.router.navigate(['/home']);
		}
  }

  getEmployees( ) {
    let user = this.storage.get('currentUser');
    let id = user.company._id;
    let url = 'secureauth/getemployees/' + id;
    this.mainService.getData(url).subscribe(res => {
      this.employees = res;

      for (var i = 0; i < res.length; i++) {
        var information = {
          details: "Employee: " + res[i].fullName + ": Updated at " + res[i].locTime,
          longitude: res[i].longitude,
          latitude: res[i].latitude
        }


        // var coordinates = {
        //   lat: res[i].latitude,
        //   lng: res[i].longitude
        // }
        // var details = "Employee: " + res[i].fullName + ": Updated at " + res[i].locTime;
        // let marker = new H.map.Marker(coordinates);
        // marker.setData("<p>" + details + "</p>");
        // marker.addEventListener('tap', event => {
        //     let bubble =  new H.ui.InfoBubble(event.target.getPosition(), {
        //         content: event.target.getData()
        //     });
        //     this.ui.addBubble(bubble);
        // }, false);
        // this.map.addObject(marker);

        console.log("Position =" + res[i].latitude);

        this.markers.push({
          position: {
            lat: res[i].latitude,
            lng: res[i].longitude,
          },
          label: {
            color: 'black',
            fontSize: "10px",
            fontWeight: "bold",
            text: res[i].fullName + ": Updated at " + res[i].locTime
          },
          title: res[i].fullName + ": Updated at " + res[i].locTime,
          info: information,
          options: {
            animation: google.maps.Animation.DROP,
          },
        })
      }

    }, err => {
      console.log(err);
    });


  }

  openEvent(result){
    this.router.navigate(['/event/' + result._id]);
  }

  setMarkers(passed) {
    for (var i = 0; i < passed.length; i++) {
      console.log(passed[i]);

      let name = passed[i].customerid.fullName;
      let address = passed[i].fullAddress ;
      let type = passed[i].type;

      let lti = (passed[i].latitude * 1);
      let ltg = (passed[i].longitude * 1);

      let details = {
        address: address,
      };
      let url = 'schedule/coords';
      const image =
      "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png";
        var information = {
          details: name + " - " + address +  " - " + type,
          longitude: ltg,
          latitude: lti
        }
        this.markers.push({
          position: {
            lat: lti,
            lng: ltg
          },
          label: {
            color: 'black',
            text: name + " - " + address +  " - " + type
          },
          title: name + " - " + address +  " - " + type,
          info: information,
          options: {
            animation: google.maps.Animation.BOUNCE,
            icon: "https://maps.google.com/mapfiles/ms/icons/blue-dot.png",
          },
        })
    }

  }

  openInfo(content) {
    this.eventdetails = content.details;
    this.smallModal.show();
    this.zoom = 14;
    this.center = {
      lat: content.latitude,
    lng: content.longitude,

    }        
  }

  closeInfo(){
    this.smallModal.hide();
    this.zoom = 10;
  }

  selectDate() {
    let url = 'schedule/calendar/' + this.user.company._id;
    console.log(this.selected);
    let input = moment(this.selected).format("MM/DD/YYYY");
    this.display = input;
    let details = {
      inputDate: input,
    };
    this.mainService.createData(details, url).subscribe((result) => {
      this.successMessage();
      let returned = this.userFilter(result);
      console.log(returned);
      this.results = returned;
      // this.results = result;
      this.markers = [];
      this.getEmployees();
      this.setMarkers(returned);
      this.count = returned.length;
      if(returned.length == 0){
        this.noResults = true;
      }else{
        this.noResults = false;
      }
    }, (err) => {
      this.errorMessage();
    });
  }

  successMessage() {
    this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Request Complete' });
  }

  errorMessage() {
    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please Try Again' });
  }

  saveSort() {
    this.updateModal.hide();
    let details = [];
    let url = 'schedule/editsort';
    for (var i = 0; i < this.results.length; i++) {
      let object = {
        id: this.results[i]._id,
        number: this.results[i].stopNumber,
      }
      details.push(object);
      console.log(object);
      let tally = (i+1)
      if(tally == this.results.length){
        console.log(details);
        this.mainService.editData(details, url).subscribe((result) => {
          console.log(result);
          this.successMessage()
        }, (err) => {
          this.errorMessage();
        });

      }
    }
    this.selectDate();
  }

  getOpenEvents(){
    let id = this.user.company._id;
    let url = 'schedule/open/' + id;
    this.mainService.getData(url).subscribe(res => {
      let returned = this.userFilter(res);
      console.log(returned);
      this.results = returned;
      this.markers = [];
      this.getEmployees();
      this.setMarkers(returned);
      this.count = returned.length;
      if(returned.length == 0){
        this.noResults = true;
      }else{
        this.noResults = false;
      }
    }, err => {
      console.log(err);
    });
  }


  getRange(){
    this.rangeModal.hide();
      let url = 'schedule/range/' + this.user.company._id;
      console.log(this.selected);
      let input = moment(this.startDate).format("MM/DD/YYYY");
      let input1 = moment(this.endDate).format("MM/DD/YYYY");
      this.display = input;
      let details = {
        startDate: input,
        endDate: input1
      };
      console.log(details);
      this.mainService.createData(details, url).subscribe((result) => {

        this.successMessage();
        let returned = this.userFilter(result);
        console.log(returned);
        this.results = returned;
        this.markers = [];
        this.getEmployees();
        this.setMarkers(returned);
        this.count = returned.length;
        if(returned.length == 0){
          this.noResults = true;
        }else{
          this.noResults = false;
        }
      }, (err) => {
        this.errorMessage();
      });

  }


  addUserFilter(user){
    this.currentFilter = user.fullName;
    this.currentFilterID = user._id;
    // this.filterModal.hide();
    this.successMessage();

  }

  removeUserFilter(){
    this.currentFilter = null;
    this.currentFilterID = null;
    // this.filterModal.hide();
    this.successMessage();
  }

  addTypeFilter(value){
    this.typeFilter = value;
    this.successMessage();
  }

  removeTypeFilter(){
    this.typeFilter = null;
    this.successMessage();
  }
  removeAllFilter(){
    this.typeFilter = null;
    this.currentFilter = null;
    this.currentFilterID = null;
    this.successMessage();
  }

  userFilter(results){
    let filtered = [];
    console.log("1 " + results);

    if(this.currentFilterID != null){
      for (var i = 0; i < results.length; i++) {
        let check = results[i].recipient.includes(this.currentFilterID)
        if(check == true){
          filtered.push(results[i]);
        }
        let tally = (i+1);
        console.log(tally);
  
        if(tally == results.length){
          console.log("2 " + filtered);
          let final = this.filterType(filtered);
          console.log("5" + final);
          return final
        }
  
      }
    }else{
      console.log("No Filter");
      let final = this.filterType(results);
      return final;
    }
  }

  filterType(results){
    console.log("3 " + results); 
    let filtered = [];
    if(this.typeFilter != null){
    for (var i = 0; i < results.length; i++) {
      let check = results[i].type == this.typeFilter;
      console.log("6" + check + results[i].type + this.typeFilter);
      if(check == true){
        filtered.push(results[i]);
      }
      let tally = (i+1);
      console.log(tally);

      if(tally == results.length){
        console.log("4 " + filtered);
        return filtered

      }

    }
  }else{
    console.log("No Filter");
    return results;
  }

  }

}
