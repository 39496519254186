import { Component, OnInit, Inject, Injectable, ViewChild} from '@angular/core';
import { MainService } from '../../main.service';
import { SESSION_STORAGE, StorageService } from 'angular-webstorage-service';
import { ActivatedRoute, Router } from '@angular/router';

import { ToastModule } from 'primeng/toast';
import { Message } from 'primeng/api';
import { MessageService } from 'primeng/api';


import { ModalDirective } from 'ngx-bootstrap/modal';

//Updated before 2020 Vacation


@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css'],
  providers: [MessageService]
})
export class AdminComponent implements OnInit {

  constructor(@Inject(SESSION_STORAGE) public storage: StorageService, public mainService: MainService, private route: ActivatedRoute, private router: Router, public messageService: MessageService) { }


  //Hide/Show Values
  public showCompanyView: boolean = false;
  public showEmployeeView: boolean = false;
  public showEmpView: boolean = false;
  public showEmpCreate: boolean = false;

  @ViewChild('smallModal') public smallModal: ModalDirective;
  @ViewChild('deleteModal') public deleteModal: ModalDirective;




  //Permission array holder
  initials: any;
  user:any;

  //Delete this after
  recipient: any;
  value: any;
  employee: any;
  employees: any;
  idbase: any;
  //

  //Permission Values
  alladmins: any;
  adminmgr: any;
  employeemgr: any;
  assigntask: any;
  allcustomers:any;
  createcustomer: any;
  viewcustomer: any;
  editcustomer: any;
  emailcustomer: any;
  deletecustomer: any;
  allcontacts: any;
  createcontact: any;
  viewcontact: any;
  editcontact: any;
  deletecontact: any;
  allnotes: any;
  createnote: any;
  viewnote: any;
  editnote: any;
  deletenote: any;
  allfiles: any;
  uploadfile: any;
  viewfile: any;
  editfile: any;
  deletefile: any;
  createevent: any;
  viewevent: any;
  editevent: any;
  deleteevent: any;
  allevents: any;
  pageAccess: any;
  createestimate: any;
  editestimate:any;
  viewestimate:any;
  deleteestimate:any;
  allestimates:any;


  //Employee Variables
  password:any;
  password1: any;
  firstName: string;
	lastName: string;
	street: string;
	city: string;
	state: string;
	title: string;
	zipcode: string;
	fullAddress: string;
	phone: any;
  email: string;
  payRate: any;

  result:any;

  ngOnInit(): void {
    this.user = this.storage.get('currentUser');
    this.initializePermissions();
  }


  hideAll() {
    this.showCompanyView = false;
    this.showEmployeeView = false;
    this.showEmpView = false;
    this.showEmpCreate = false;
  }

  showCompany() {
    this.hideAll();
    this.showCompanyView = true;
  }


  getEmployees() {
    this.hideAll();
    this.showEmployeeView = true;
    this.idbase = this.storage.get('currentUser');
    let id = this.idbase.company._id;
    let url = 'secureauth/getemployees/' + id;
    this.mainService.getData(url).subscribe(res => {
      this.employees = res;
    }, err => {
      console.log(err);
    });


  }

  //Initialize values of permission
  initializePermissions() {
    //Admin Controls
    this.alladmins = ["ADMINMANAGER", 'EMPLOYEEMANAGER', "ASSIGNTASK"];
    this.adminmgr = "ADMINMANAGER";
    this.employeemgr = "EMPLOYEEMANAGER";
    this.assigntask = "ASSIGNTASK";
    //Customer Controls
    this.allcustomers = ["CREATECUSTOMER", 'VIEWCUSTOMER', "EDITCUSTOMER", 'DELETECUSTOMER', 'EMAILCUSTOMER'];
    this.createcustomer = "CREATECUSTOMER";
    this.viewcustomer = 'VIEWCUSTOMER';
    this.editcustomer = "EDITCUSTOMER";
    this.emailcustomer = 'EMAILCUSTOMER';
    this.deletecustomer = 'DELETECUSTOMER';
    // Contact Controls
    this.allcontacts = ["CREATECONTACT", 'VIEWCONTACT', "EDITCONTACT", 'DELETECONTACT'];
    this.createcontact = "CREATECONTACT";
    this.viewcontact = 'VIEWCONTACT';
    this.editcontact = "EDITCONTACT";
    this.deletecontact = 'DELETECONTACT';
    // Note Controls
    this.allnotes = ["CREATENOTE", 'VIEWNOTE', "EDITNOTE", 'DELETENOTE'];
    this.createnote = "CREATENOTE";
    this.viewnote = 'VIEWNOTE';
    this.editnote = "EDITNOTE";
    this.deletenote = 'DELETENOTE';
        // File Controls
    this.allfiles = ["CREATEFILE", 'VIEWFILE', "EDITFILE", 'DELETEFILE'];
    this.uploadfile= "CREATEFILE";
    this.viewfile = 'VIEWFILE';
    this.editfile = "EDITFILE";
    this.deletefile = 'DELETEFILE';

    this.allevents = ["CREATEEVENT", 'VIEWEVENT', "EDITEVENT", 'DELETEEVENT'];
    this.createevent = "CREATEEVENT";
    this.viewevent = 'VIEWEVENT';
    this.editevent = "EDITEVENT";
    this.deleteevent = 'DELETEEVENT';

    this.allestimates = ["CREATEESTIMATE", 'VIEWESTIMATE', "EDITESTIMATE", 'DELETEESTIMATE'];
    this.createestimate = "CREATEESTIMATE";
    this.viewestimate = 'VIEWESTIMATE';
    this.editestimate = "EDITESTIMATE";
    this.deleteestimate = 'DELETEESTIMATE';
  }

  getEmployee(id) {

    this.showEmpView = true;
    this.showEmpCreate = false;
    let url = 'secureauth/user/' + id;
    this.mainService.getData(url).subscribe(res => {
      this.employee = res;
    }, err => {
      console.log(err);
    });

  }



  //Add/Remove permission to the employee
  editCompany(company) {

    let url = 'company/';
    this.mainService.editData(company, url).subscribe((result) => {
    }, (err) => {
      console.log(err);
    });

  }

  //Edit Employee
  editEmployee(employee) {

    let url = 'secureauth/edit/' + employee._id
    this.mainService.editData(employee, url).subscribe((result) => {
      this.getEmployees();
      let id = this.employee._id;
      this.getEmployee(id);
    }, (err) => {
      console.log(err);
    });

    // let id = this.employee._id;
    // // this.getEmployees();
    // this.getEmployee(id);
    this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Employee Updated' });

  }

  addPermission(value) {
    this.employee.pageAccess.push(value);
    this.updateAccess();
  }

  checkControl(value){
    if(this.employee._id != this.idbase.company.creator){
      this.removePermission(value);
    }else{
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Admin Permissions Required for Company Owner'});
    }

  }

  removePermission(value) {

    var index = this.employee.pageAccess.indexOf(value);
    if (index > -1) {
      this.employee.pageAccess.splice(index, 1)
      this.updateAccess();
    }
  }

  createPermission(value) {
    this.pageAccess.push(value);
  }

  cancelPermission(value) {

    var index = this.pageAccess.indexOf(value);
    if (index > -1) {
      this.pageAccess.splice(index, 1)
    }
  }

  createPermissions(value) {
    console.log(value.length + value)
    for (var i = 0; i < value.length; i++) {
    this.pageAccess.push(value[i]);
    }
  }

  cancelPermissions(value) {
    console.log(value.length + value)
    for (var i = 0; i < value.length; i++) {
      var index = this.pageAccess.indexOf(value[i]);
      if (index > -1) {
        this.pageAccess.splice(index, 1)
      }
    }
  }


  addPermissions(value) {
    console.log(value.length + value)
    for (var i = 0; i < value.length; i++) {
    this.employee.pageAccess.push(value[i]);
    }
    if(i == value.length){
      this.updateAccess();
        }
  }

  removePermissions(value) {
    console.log(value.length + value)
    for (var i = 0; i < value.length; i++) {
      var index = this.employee.pageAccess.indexOf(value[i]);
      if (index > -1) {
        this.employee.pageAccess.splice(index, 1)
      }
    }
    if(i == value.length){
      this.updateAccess();
      console.log("Option -- 2");
    }
  }




  //Add/Remove permission to the employee
  updateAccess() {

    let url = 'secureauth/permission/' + this.employee._id
    this.mainService.editData(this.employee, url).subscribe((result) => {
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Permission Updated' });
    }, (err) => {
      console.log(err);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please Try Again' });
    });
    if (this.user._id == this.employee._id){
      this.updateCurrent(this.employee._id);
    }
  }

  updateCurrent(id) {
    // console.log("Update ID" + id)
    // console.log("Self Permissions Updated");
    // this.showEmpView = true;
    let url = 'secureauth/user/' + id;
    this.mainService.getData(url).subscribe(res => {
      this.user = res;
      this.storage.set('currentUser', res);
    }, err => {
      console.log(err);
    });

  }

  showCreate(){
    this.pageAccess = [];
    this.showEmpCreate = true;
    this.showEmpView = false;

  }

  create() {
		if(this.phone != null){
			var p1 = this.phone.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")
		  }else{
			p1 = ""
		  }
      if(this.payRate == null || this.payRate == undefined || this.payRate == ""){
        this.payRate = 0
        }
		let details = {
			firstName: this.firstName,
			lastName: this.lastName,
			street: this.street,
			city: this.city,
			state: this.state,
			zipcode: this.zipcode,
			fullAddress: this.street + " " + this.city + ", " + this.state + " " + this.zipcode,
      phone: p1,
      pageAccess: this.pageAccess,
      email: this.email,
      password: this.password,
      payRate: this.payRate,
      company: this.user.company._id,
      owneremail: this.user.company.email
    }
    let link = 'secureauth/createemployee';
    console.log(details);
		this.mainService.createData(details, link).subscribe((result) => {
      let id = result._id;
      this.getEmployees();
      this.getEmployee(id);
		}, (err) => {
      this.messageService.add({severity:'error', summary: 'An Error Occurred', detail:'Please Try Again.'});
      if(err.error.error == 'User already exists.'){
      this.smallModal.show();
      }
		});
  }
  
  showDelete(id){
    this.result = id;
    this.deleteModal.show();

  }
  deleteEmployee() {
    let id = this.result
    if (this.user.pageAccess.includes('EMPLOYEEMANAGER') == true && id != this.user.company.creator) {
      let url = 'secureauth/deleteemployee/' + this.result;
      this.mainService.deleteData(url).subscribe(res => {
        this.messageService.add({severity:'error', summary: 'Success', detail:'Employee Removed.'});
        this.getEmployees();
        this.deleteModal.hide();
      }, (err) => {
        console.log("An Error Occurred.");
        this.messageService.add({severity:'error', summary: 'An Error Occurred', detail:'Please Try Again.'});
        this.deleteModal.hide();
      });
    } else {
      this.messageService.add({severity:'error', summary: 'Not Authorized', detail:'Permission Not Granted For Action or Owner Account can not be removed'});
      this.deleteModal.hide();
    }
  }


}
