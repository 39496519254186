import { INavData } from '@coreui/angular';

export const navItems: INavData[] = [
  {
    name: 'Dashboard',
    url: '/home',
    icon: 'icon-home',
  },
    {
    name: 'Calendar',
    url: '/calendar',
    icon: 'icon-calendar',
  },
    {
    name: 'Accounting',
    url: '/accounting',
    icon: 'icon-wallet',
  },
  // {
  //   name: 'Files',
  //   url: '/home',
  //   icon: 'icon-folder',
  // },
  // {
  //   name: 'Fleet',
  //   url: '/home',
  //   icon: 'icon-grid',
  // },
  // {
  //   name: 'Bids',
  //   url: '/home',
  //   icon: 'icon-envelope',
  // },
  // {
  //   name: 'Inventory',
  //   url: '/home',
  //   icon: 'icon-docs',
  // },
 

];
