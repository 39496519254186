import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './profile/profile.component';

// PrimeNG
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { SidebarModule } from 'primeng/sidebar';
import { TabViewModule } from 'primeng/tabview';
import { InputSwitchModule } from 'primeng/inputswitch';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { DropdownModule } from 'primeng/dropdown';
import { InputMaskModule } from 'primeng/inputmask';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { DialogModule } from 'primeng/dialog';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FormsModule } from '@angular/forms';
import { ToastModule } from 'primeng/toast';
import { CalendarModule } from 'primeng/calendar';
import { TabsModule } from 'ngx-bootstrap/tabs';

import { ChartsModule } from 'ng2-charts';

import { NgxMaskModule, IConfig } from 'ngx-mask';
export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;


import { CarouselModule } from 'ngx-bootstrap/carousel';
import { AdminComponent } from './admin/admin.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PdfViewerModule } from 'ng2-pdf-viewer';

// RECOMMENDED
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CalendarComponent } from './calendar/calendar.component';
import { AccountingComponent } from './accounting/accounting.component';
import { EventComponent } from './event/event.component';
import { ContactsComponent } from './contacts/contacts.component';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NotesComponent } from './notes/notes.component';
import { FilesComponent } from './files/files.component';
import { EventsComponent } from './events/events.component';
import { TasksComponent } from './tasks/tasks.component';
import { TicketsComponent } from './tickets/tickets.component';
import { RemindersComponent } from './reminders/reminders.component';
import { ProjectComponent } from './project/project.component';
import { ProjectsComponent } from './projects/projects.component';
import { TmodalComponent } from './tmodal/tmodal.component';
import { SubportalComponent } from './subportal/subportal.component';
import { CustomerportalComponent } from './customerportal/customerportal.component';
import { VaultComponent } from './vault/vault.component';
import { PasswordComponent } from './password/password.component';

import { GoogleMapsModule } from '@angular/google-maps';
import { EstimateComponent } from './estimate/estimate.component';
import { EstimatesComponent } from './estimates/estimates.component';



@NgModule({
	declarations: [HomeComponent, ProfileComponent, AdminComponent, CalendarComponent, AccountingComponent, EventComponent, ContactsComponent, NotesComponent, FilesComponent, EventsComponent, TasksComponent, TicketsComponent, RemindersComponent, ProjectComponent, ProjectsComponent, TmodalComponent, SubportalComponent, CustomerportalComponent, VaultComponent, PasswordComponent, EstimateComponent, EstimatesComponent],
	imports: [
		CommonModule,
		DialogModule,
		CarouselModule.forRoot(),
		ModalModule.forRoot(),
		NgxLoadingModule.forRoot({
			animationType: ngxLoadingAnimationTypes.cubeGrid,
			backdropBackgroundColour: 'rgba(0,0,0,0.1)',
			backdropBorderRadius: '4px',
			primaryColour: '#000026',
			secondaryColour: '#e0b500',
			tertiaryColour: '#e0b500'
		}),

		SidebarModule,
		NgbModule,
		ChartsModule,
		PdfViewerModule,
		CalendarModule,
		GoogleMapsModule,
		OverlayPanelModule,
		TabViewModule,
		InputSwitchModule,
		TabsModule,
		AutoCompleteModule,
		InputMaskModule,
		FormsModule,
		ToastModule,
		DropdownModule,
		NgxMaskModule.forRoot(options),
		NgxLoadingModule.forRoot({
			animationType: ngxLoadingAnimationTypes.cubeGrid,
			backdropBackgroundColour: 'rgba(0,0,0,0.1)',
			backdropBorderRadius: '4px',
			primaryColour: '#000026',
			secondaryColour: '#e0b500',
			tertiaryColour: '#e0b500'
		}),
		BrowserAnimationsModule,
		BsDropdownModule.forRoot()
	]
})
export class PagesModule { }
