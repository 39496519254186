import { Component, OnInit, Inject, Injectable, ViewChild } from '@angular/core';
import { MainService } from '../../main.service';
import { SESSION_STORAGE, StorageService } from 'angular-webstorage-service';
import { Routes, RouterModule, ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import * as moment from 'moment';


import {ToastModule} from 'primeng/toast';
import { Message } from 'primeng/api';
import { MessageService } from 'primeng/api';

import {ModalDirective} from 'ngx-bootstrap/modal';

import { TasksComponent } from '../tasks/tasks.component';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  providers: [MessageService]
})
export class HomeComponent implements OnInit {


  @ViewChild('tasks') private tasksModal: TasksComponent;
  results:any
  shift:any;
  user:any;
  url:any;
  date:any;
  title:any;
  count:any;

  public noResults: boolean = false;
  public showSchedule: boolean = true;
  public showShift: boolean = false;


  constructor(
    @Inject(SESSION_STORAGE) public storage: StorageService, 
    public mainService: MainService, 
    private route: ActivatedRoute, 
    private router: Router, 
    public messageService: MessageService,
    ) { }

  ngOnInit(): void {
    this.initialize();
    this.checkToken();
  }
  
  checkToken(){
    let value = this.mainService.isTokenExpired();
    console.log("Token Validity " + value);
    if (value == true){
      //Return to login
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Token Expired, Please Login Again' });
      this.storage.set('token', '');
      this.storage.set('currentUser', '');
      this.router.navigate(['/']);
    }
  }

  async initialize(){
    this.user = this.storage.get('currentUser');
    this.url = 'schedule/get/service&' + this.user._id;
    this.getSchedule();
    this.title = "Service";

    // For Employee schedule
    this.date = moment().format("MM/DD/YYYY");
  }

  getService(){
    this.title = "Service";
    this.showSchedule = true;
    this.showShift = false;
    this.url = 'schedule/get/service&' + this.user._id;
    this.getSchedule();
  }

  getEstimate(){
    this.title = "Estimate";
    this.showSchedule = true;
    this.showShift = false;
    this.url = 'schedule/get/estimate&' + this.user._id;
    this.getSchedule();
  }

  getShift(){
    this.showSchedule = false;
    this.showShift = true;
    this.noResults = true;
    // this.getSchedule();
  }

  openEvent(result){
    this.router.navigate(['/event/' + result._id]);
  }

  async getSchedule(){
    console.log("Schedule Clicked");
    this.mainService.getData(this.url).subscribe(res => {
      let results = res;
      this.results = res;
      this.count = res.length;
      if(res.length == 0)
      {
        this.noResults = true;
      }else{
        this.noResults = false;
      }
     })
  }

  successMessage() {
    this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Request Complete' });
  }

  errorMessage() {
    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please Try Again' });
  }

  async openTaskModal() {
    this.storage.set('customer', null);
    this.storage.set('project', null);
    this.storage.set('event', null);
    this.storage.set('user', this.user);
    this.storage.set('geturl', 'tasks/get/user&' + this.user._id);
    return this.tasksModal.open();
  }

}
