import { Component, OnInit, Inject, Injectable } from '@angular/core';
import { MainService } from '../../main.service';
import { SESSION_STORAGE, StorageService } from 'angular-webstorage-service';
import { Routes, RouterModule, ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import * as moment from 'moment';

import { ToastModule } from 'primeng/toast';
import { Message } from 'primeng/api';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.css'],
  providers: [MessageService]
})
export class PasswordComponent implements OnInit {

  public showPassword: boolean = false;

  public email: any;
  public password: any;
  public password1: any;
  public code: any;

  constructor(@Inject(SESSION_STORAGE) public storage: StorageService, public mainService: MainService, private route: ActivatedRoute, private router: Router, public messageService: MessageService) { }

  ngOnInit(): void {

    this.checkCode();
  }

  checkCode(){
    let email = this.route.snapshot.params['email'];
    let code = this.route.snapshot.params['code'];
    let url = 'auth/checkreset/' + email + "&" + code;
    this.mainService.getData(url).subscribe(res => {
        this.showPassword = true;
    }, err => {
      console.log(err);
      this.errorMessage();
      this.router.navigate(['/login']);

    });
  }

  savePassword() {
    let email = this.route.snapshot.params['email'];
    let code = this.route.snapshot.params['code'];
    let details = {
      email: email,
      password: this.password,
      code: code,
    };
    let url = 'auth/password';
    this.mainService.editData(details, url).subscribe((result) => {
      this.successMessage();
      this.router.navigate(['/login']);
    }, (err) => {
      console.log("An Error Occurred.");
      this.errorMessage();
    });
  }

  successMessage() {
    this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Request Complete' });
  }

  errorMessage() {
    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please Try Again' });
  }

}
