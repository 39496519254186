import { Component, OnInit, Inject, Injectable, ViewChild } from '@angular/core';
import { MainService } from '../../main.service';
import { SESSION_STORAGE, StorageService } from 'angular-webstorage-service';
import { Routes, RouterModule, ActivatedRoute, Router, NavigationEnd } from '@angular/router';

import { ToastModule } from 'primeng/toast';
import { Message } from 'primeng/api';
import { MessageService } from 'primeng/api';

import * as moment from 'moment';

import { EventsComponent } from '../events/events.component';
import { TasksComponent } from '../tasks/tasks.component';

import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.css'],
  providers: [MessageService]
})
export class EventComponent implements OnInit {

  @ViewChild('events') private eventsModal: EventsComponent;
  @ViewChild('tasks') private tasksModal: TasksComponent;
  @ViewChild('smallModal') public smallModal: ModalDirective;
  @ViewChild('completeModal') public completeModal: ModalDirective;
  @ViewChild('filedeleteModal') public filedeleteModal: ModalDirective;

  public id: any;
  public user: any;
  public customer: any;
  navigationSubscription: any;
  loading:any;

  public noResults: boolean = false;
  public showFileUpload: boolean = false;
  public showAllFiles: boolean = false;
  public showOneFile: boolean = false;
  public showFile: boolean = false;
  public showPDF: boolean = false;

  public showTabContainer: boolean = false;
  public showMainContainer: boolean = true;
  public showButton: boolean = false;

  fileurl:any;

  description:any;

  public result: any;
  public results: any;

  event: any;
  status:any;
  color:any;

  constructor(@Inject(SESSION_STORAGE) public storage: StorageService, public mainService: MainService, private route: ActivatedRoute, private router: Router, public messageService: MessageService) 
  { 
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        this.initialiseInvites();
      }
    });
  }

  ngOnInit(): void {
    this.checkToken();
    this.user = this.storage.get('currentUser');
    let user = this.storage.get('currentUser');
    if (user.pageAccess.includes("VIEWCUSTOMER") == true) {
      this.initalizeProfile();
    } else {
      this.router.navigate(['/home']);
    }
    if (user.pageAccess.includes("VIEWFILE") == true) {
      this.showAllFiles = true;
    }
  }

  checkToken(){
    let value = this.mainService.isTokenExpired();
    if (value == true){
      //Return to login
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Token Expired, Please Login Again' });
      this.storage.set('token', '');
      this.storage.set('currentUser', '');
      this.router.navigate(['/']);
    }
  }

  initalizeProfile() {

    this.id = this.route.snapshot.params['id'];
    let id = this.route.snapshot.params['id'];
    let url = 'schedule/get/profile&' + id;
    this.mainService.getData(url).subscribe(res => {
      this.event = res;

      this.results = res.files;
      if(res.files.length == 0){
        this.noResults = true;
      }else{
        this.noResults = false;
      }
    }, err => {
      console.log(err);
    });

  }

  initialiseInvites() {
    this.closeTabs();
    this.showButton = false;
    this.showTabContainer = false;
    this.ngOnInit();
  }

  openCustomer(){
    this.router.navigate(['/profile/' + this.event.customerid._id]);
  }

  openEstimate(){
    this.router.navigate(['/estimate/' + this.event.estimateID._id]);
  }

  openProject(){
    this.router.navigate(['/project/' + this.event.projectid._id]);
  }


  closeTabs() {
    this.showMainContainer = true;
    this.showButton = false;
    this.showTabContainer = false;

    this.showFileUpload = false;
    this.showAllFiles = false;
  }

  authorizedError() {
    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Not Authorized' });
  }

  deleteEvent() {
    if (this.user.pageAccess.includes("DELETEEVENT") == true) {
      const id = this.route.snapshot.params['id'];
      let url = 'schedule/delete/' + id;
      this.mainService.deleteData(url).subscribe(res => {
        this.router.navigate(['/home']);
      }, (err) => {
        console.log("An Error Occurred.");
      });
    } else {
      this.authorizedError();
      this.smallModal.hide();
    }
  }

  async openTaskModal() {
    let id = this.route.snapshot.params['id'];
    this.storage.set('customer', this.event.customerid._id);
    this.storage.set('project', null);
    this.storage.set('event', id);
    this.storage.set('user', this.user);
    this.storage.set('geturl', 'tasks/get/event&' + id);
    return this.tasksModal.open();
  }

  async openEventEdit() {
    var options = {
      type: "Edit",
      url: 'schedule/edit/' + this.route.snapshot.params['id'],
      CustID: this.event.customerid._id,
      EstimateID: null,
      ProjectID: null,
      event: this.event,
      equipment: this.event.equipment,
      viewtype: "Edit"

    }
    return this.eventsModal.open(options);
  }


   //Begin File Section

   imageClick() {
    const fileInput = document.getElementById('file-upload-input');
    fileInput.click();
  }

  fileInputChange(event: EventTarget) {
    let token = this.storage.get('token');
    this.loading = true;

    let id = this.route.snapshot.params['id'];

    const eventObj: MSInputMethodContext = event as MSInputMethodContext;
    const target: HTMLInputElement = eventObj.target as HTMLInputElement;
    const file: File = target.files[0];
    const url = 'https://kenectin.com/api/files/upload/event&' + id;

    const ext = file.name.split('.').pop();
    let customer = this.id;
    let user = this.user.company._id;
    let time = moment().format("MMDDYYhhmmssa");
    let filename = customer + "-" + user + "-" + time + "." + ext;
    console.log(filename + ext)
    const formData = new FormData();
    formData.append('file', file, filename);
    fetch(url, {
      method: 'POST',
      headers: new Headers({
        'Authorization': 'Bearer ' + token
      }),
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        this.loading = false;
        this.messageService.add({ severity: 'success', summary: 'Upload Successful', detail: 'File Created' });
        this.initalizeProfile();
      })
      .catch(error => {
        this.loading = false;
        console.error('error uploading image');
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Upload failed' });
      })
  }

  openFile(item){
    this.showFile = false;
    this.showPDF = false;
    this.showOneFile = true;
    this.result = item;
    this.fileurl = item.url;
    this.description = item.description;
    console.log(item.url);
    console.log("Result " + this.result);
    if (item.format == 'application/pdf'){
      this.showPDF = true;
      console.log(item.format == 'application/pdf');
    }else{
      this.showFile = true;
    }
  }

  downloadFile() {
    window.open(this.fileurl);
    this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Download Started' });
}


  editFile(){
    if(this.user.pageAccess.includes("EDITFILE") == true){
      for (var i = 0; i < this.event.files.length; i++) {
        if (this.result.fid == this.event.files[i].fid){
          this.event.files[i].description = this.description;
          this.saveFile();
      }
    }
      }else{
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Not Authorized' });
      }
  }
  
  deleteFile(){
    if(this.user.pageAccess.includes("DELETEFILE") == true){
    for (var i = 0; i < this.event.files.length; i++) {
      if (this.result.fid == this.event.files[i].fid){
        this.event.files.splice(i, 1);
        this.saveFile();
        this.showOneFile = false;
        this.filedeleteModal.hide();
    }
    // if(i == this.event.files.length){
    //   this.saveFile();
    //   this.showOneFile = false;
    //   this.filedeleteModal.hide();
    // }
  }
  }else{
    this.filedeleteModal.hide();
    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Not Authorized' });
  }
  }
  
  
  saveFile(){
    this.showButton = false;
    let id = this.route.snapshot.params['id']; 
    let url = 'schedule/savefiles/' + id;
  this.mainService.editData(this.event, url).subscribe((result) => {
    this.filedeleteModal.hide();
    this.event = result;
    this.messageService.add({ severity: 'success', summary: 'Upload Successful', detail: 'File Updated' });
     this.initalizeProfile();
  }, (err) => {
    console.log("An Error Occurred.");
    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Request Failed' });
  });
  }

  //End File Section
  

  confirmed(){
    this.status = "Customer Confirmed";
    this.color = "#3fd2fa";
    this.updateStatus();
  }

  enRoute(){
    this.status = "En Route";
    this.color = "#456789";
    this.updateStatus();
    let url2 = 'schedule/notify/' + this.route.snapshot.params['id'];
    this.mainService.getData(url2).subscribe((result) => {
      console.log(result);
    }, (err) => {
      console.log("An Error Occurred.")
    });
  }

  onSite(){
    this.status = "On-Site";
    this.color = "#3e4f89";
    this.updateStatus();
  }

  followUp(){
    this.status = "Follow Up / Manager Required";
    this.color = "#9f2800";
    this.updateStatus();
  }
  
  updateStatus(){
    let id = this.event._id;
    let contact = {
        eventid: id,
        completeStat: "Open",
        stopNumber: this.event.stopNumber,
        requestStat: this.event.requestStat,
        jobStatus: this.status,
        statusColor: this.color
    };
   let url = 'schedule/sort'
   this.mainService.editData(contact, url).subscribe((result) => {
     console.log(result);
     this.initialiseInvites();
     this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Update Complete' });
   }, (err) => {
     console.log("An Error Occurred.")
   });
   }
  
   requestCompleted(){
    let id = this.event._id;
    let contact = {
        eventid: id,
        completeStat: "Complete",
        stopNumber: this.event.stopNumber,
        requestStat: 'Complete',
        jobStatus: 'Job Completed',
        statusColor: '#79C142'
    };
    let url = 'schedule/sort'
   this.mainService.editData(contact, url).subscribe((result) => {
     console.log(result);
     this.completeModal.hide();
     this.initialiseInvites();
     this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Update Complete' });
   }, (err) => {
     console.log("An Error Occurred.")
   });
   }

}
